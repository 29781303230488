import { FC } from "react"
import Box from "./box"

import drowsyPower from '../images/icons/bonuses/drowsy-power.png'
import sleepExp from '../images/icons/bonuses/sleep-exp.png'
import sleepPoints from '../images/icons/bonuses/sleep-points.png'
import { BONUS_TYPE } from "../data/events"

interface Props {
    type: BONUS_TYPE
    size?: number
}

const BonusIcon: FC<Props> = ({ type, size }) => {
    let icon;
    switch (type) {
        case BONUS_TYPE.DrowsyPower:
            icon = drowsyPower;
            break;
        case BONUS_TYPE.SleepEXP:
            icon = sleepExp;
            break;
        case BONUS_TYPE.SleepPoints:
            icon = sleepPoints;
            break;
        default:
            icon = sleepExp;
    }

    return (
        <Box bg="grey.0" p={1} display="flex" position="relative" top="0px">
            <img src={icon} style={{maxWidth: size}} alt="" />
        </Box>
    )
}

export default BonusIcon