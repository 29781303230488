import styled from "styled-components";
import { space, SpaceProps } from "@techstack/styled-system";

const Wrapper = styled.div<SpaceProps>`
    width: 100%;
    max-width: 100%;
    ${space}
    
    @supports(padding: max(0px)) {
        padding-left: max(.75rem, env(safe-area-inset-left));
        padding-right: max(.75rem, env(safe-area-inset-right));
    }
`

export default Wrapper;