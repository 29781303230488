import { ISLAND, SLEEP_TYPE, SNORLAX_RATING } from "./islands";

interface IslandLocation {
    island: ISLAND
    sleepType: SLEEP_TYPE
    snorlaxRating: SNORLAX_RATING
}
export interface Pokemon {
    name: string
    pokemonId: number
    sleepType: SLEEP_TYPE
    carryLimit: number
    frienshipPoints: number
    mainSkill: string
    locations: Array<IslandLocation>
}


const pokemon: Array<Pokemon> = [
    {
        pokemonId: 1,
        name: 'Bulbausaur',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
        ]
    },
    {
        pokemonId: 2,
        name: 'Ivysaur',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
        ]
    },
    {
        pokemonId: 3,
        name: 'Venusaur',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Master5
            },
        ]
    },
    {
        pokemonId: 4,
        name: 'Charmander',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 5,
        name: 'Charmeleon',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic5
            },
        ]
    },
    {
        pokemonId: 6,
        name: 'Charizard',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master5
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master1
            },
        ]
    },
    {
        pokemonId: 7,
        name: 'Squirtle',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 8,
        name: 'Wartortle',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 9,
        name: 'Blastoise',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Master5
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Master2
            },
        ]
    },
    {
        pokemonId: 10,
        name: 'Caterpie',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 11,
        name: 'Metapod',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 12,
        name: 'Butterfree',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Ultra5
            },
        ]
    },
    {
        pokemonId: 19,
        name: 'Rattata',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 20,
        name: 'Raticate',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic5
            },
        ]
    },
    {
        pokemonId: 23,
        name: 'Ekans',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 24,
        name: 'Arbok',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 25,
        name: 'Pikachu',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 26,
        name: 'Raichu',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 39,
        name: 'Jigglypuff',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 40,
        name: 'Wigglytuff',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra4
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra3
            },
        ]
    },
    {
        pokemonId: 50,
        name: 'Diglett',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 51,
        name: 'Dugtrio',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great5
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 52,
        name: 'Meowth',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
        ]
    },
    {
        pokemonId: 53,
        name: 'Persian',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra1
            },
        ]
    },
    {
        pokemonId: 54,
        name: 'Psyduck',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 55,
        name: 'Golduck',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great2
            },
        ]
    },
    {
        pokemonId: 56,
        name: 'Mankey',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 57,
        name: 'Primeape',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 58,
        name: 'Growlithe',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 59,
        name: 'Arcanine',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: []
    },
    {
        pokemonId: 69,
        name: 'Bellsprout',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 70,
        name: 'Weepinbell',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
        ]
    },
    {
        pokemonId: 71,
        name: 'Victreebel',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: []
    },
    {
        pokemonId: 74,
        name: 'Geodude',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 75,
        name: 'Graveler',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great3
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
        ]
    },
    {
        pokemonId: 76,
        name: 'Golem',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Ultra5
            },
        ]
    },
    {
        pokemonId: 79,
        name: 'Slowpoke',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 80,
        name: 'Slowbro',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra3
            },
        ]
    },
    {
        pokemonId: 81,
        name: 'Magnemite',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 82,
        name: 'Magneton',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great2
            },
        ]
    },
    {
        pokemonId: 84,
        name: 'Doduo',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 85,
        name: 'Dodrio',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great5
            },
        ]
    },
    {
        pokemonId: 92,
        name: 'Gastly',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 93,
        name: 'Haunter',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic5
            },
        ]
    },
    {
        pokemonId: 94,
        name: 'Gengar',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra5
            },
        ]
    },
    {
        pokemonId: 104,
        name: 'Cubone',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 105,
        name: 'Marowak',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great2
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
        ]
    },
    {
        pokemonId: 115,
        name: 'Kangaskhan',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Ultra2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
        ]
    },
    {
        pokemonId: 127,
        name: 'Pinsir',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Ultra2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
        ]
    },
    {
        pokemonId: 132,
        name: 'Ditto',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic5
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 133,
        name: 'Eevee',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 134,
        name: 'Vaporeon',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Master2
            },
        ]
    },
    {
        pokemonId: 135,
        name: 'Jolteon',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master5
            },
        ]
    },
    {
        pokemonId: 136,
        name: 'Flareon',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: []
    },
    {
        pokemonId: 152,
        name: 'Chikorita',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 155,
        name: 'Cyndaquil',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 158,
        name: 'Totodile',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 159,
        name: 'Croconaw',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 160,
        name: 'Feraligatr',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Master2
            },
        ]
    },
    {
        pokemonId: 172,
        name: 'Pichu',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 174,
        name: 'Igglybuff',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 175,
        name: 'Togepi',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 176,
        name: 'Togetic',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
        ]
    },
    {
        pokemonId: 179,
        name: 'Mareep',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 180,
        name: 'Flaaffy',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Great2
            },
        ]
    },
    {
        pokemonId: 185,
        name: 'Sudowoodo',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 196,
        name: 'Espeon',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master1
            },
        ]
    },
    {
        pokemonId: 197,
        name: 'Umbreon',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Master1
            },
        ]
    },
    {
        pokemonId: 199,
        name: 'Slowking',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master2
            },
        ]
    },
    {
        pokemonId: 202,
        name: 'Woobuffet',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 214,
        name: 'Heracross',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Ultra2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great4
            },
        ]
    },
    {
        pokemonId: 228,
        name: 'Houndour',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 246,
        name: 'Larvitar',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 247,
        name: 'Pupitar',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Great1
            },
        ]
    },
    {
        pokemonId: 248,
        name: 'Tyranitar',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Master6
            },
        ]
    },
    {
        pokemonId: 287,
        name: 'Slakoth',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
        ]
    },
    {
        pokemonId: 288,
        name: 'Vigoroth',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great3
            },
        ]
    },
    {
        pokemonId: 289,
        name: 'Slaking',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master7
            },
        ]
    },
    {
        pokemonId: 302,
        name: 'Sableye',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great2
            },
        ]
    },
    {
        pokemonId: 316,
        name: 'Gulpin',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 317,
        name: 'Swalot',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great2
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
        ]
    },
    {
        pokemonId: 333,
        name: 'Swablu',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 359,
        name: 'Absol',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Ultra2
            },
        ]
    },
    {
        pokemonId: 360,
        name: 'Wynaut',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 363,
        name: 'Spheal',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 438,
        name: 'Bonsly',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 447,
        name: 'Riolu',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 453,
        name: 'Croagunk',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic1
            },
        ]
    },
    {
        pokemonId: 454,
        name: 'Toxicroak',
        sleepType: SLEEP_TYPE.Dozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Great2
            },
            {
                island: ISLAND.Cyan,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic4
            },
            {
                island: ISLAND.Taupe,
                sleepType: SLEEP_TYPE.Dozing,
                snorlaxRating: SNORLAX_RATING.Basic3
            },
        ]
    },
    {
        pokemonId: 462,
        name: 'Magnezone',
        sleepType: SLEEP_TYPE.Slumbering,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Slumbering,
                snorlaxRating: SNORLAX_RATING.Master7
            },
        ]
    },
    {
        pokemonId: 468,
        name: 'Togekiss',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master6
            },
        ]
    },
    {
        pokemonId: 700,
        name: 'Sylveon',
        sleepType: SLEEP_TYPE.Snoozing,
        carryLimit: 0,
        frienshipPoints: 0,
        mainSkill: '',
        locations: [
            {
                island: ISLAND.Greengrass,
                sleepType: SLEEP_TYPE.Snoozing,
                snorlaxRating: SNORLAX_RATING.Master3
            },
        ]
    },
]

export default pokemon