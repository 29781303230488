import { FC } from "react"
import { MenuImg } from "./recipe-icon"
import Box from "./box"

import { INGREDIENT } from "../data/recipes"

import apple from '../images/ingredients/fancy-apple.png'
import cacao from '../images/ingredients/soothing-cacao.png'
import egg from '../images/ingredients/fancy-egg.png'
import ginger from '../images/ingredients/warming-ginger.png'
import honey from '../images/ingredients/honey.png'
import milk from '../images/ingredients/moomoo-milk.png'
import mushroom from '../images/ingredients/tasty-mushroom.png'
import oil from '../images/ingredients/pure-oil.png'
import potato from '../images/ingredients/soft-potato.png'
import soybeans from '../images/ingredients/greengrass-soybeans.png'
import tomato from '../images/ingredients/snoozy-tomato.png'
import sausage from '../images/ingredients/bean-sausage.png'
import herb from '../images/ingredients/fiery-herb.png'
import leek from '../images/ingredients/large-leek.png'
import slowpokeTail from '../images/ingredients/slowpoke-tail.png'

interface Props {
    type: INGREDIENT
    size?: number
    bg?: string
}

const IngredientIcon: FC<Props> = ({ type, size, bg = "grey.2" }) => {
    let icon;
    switch (type) {
        case INGREDIENT.Apple:
            icon = apple;
            break;
        case INGREDIENT.Cacao:
            icon = cacao;
            break;
        case INGREDIENT.Egg:
            icon = egg;
            break;
        case INGREDIENT.Ginger:
            icon = ginger;
            break;
        case INGREDIENT.Honey:
            icon = honey;
            break;
        case INGREDIENT.Milk:
            icon = milk;
            break;
        case INGREDIENT.Mushroom:
            icon = mushroom;
            break;
        case INGREDIENT.Oil:
            icon = oil;
            break;
        case INGREDIENT.Potato:
            icon = potato;
            break;
        case INGREDIENT.Soybeans:
            icon = soybeans;
            break;
        case INGREDIENT.Tomato:
            icon = tomato;
            break;
        case INGREDIENT.Sausage:
            icon = sausage;
            break;
        case INGREDIENT.Herb:
            icon = herb;
            break;
        case INGREDIENT.Leek:
            icon = leek;
            break;
        case INGREDIENT.SlowpokeTail:
            icon = slowpokeTail;
            break;
        default:
            icon = apple;
    }

    return (
        <Box bg={bg} p={1} display="inline-block" borderRadius="50%" position="relative" top="0px">
            <MenuImg src={icon} maxSize={size} alt="" />
        </Box>
    )
}

export default IngredientIcon