import { FC } from "react"
import Box from "./box"

import pok1 from '../images/pokemon/pokemon_1.png'
import pok2 from '../images/pokemon/pokemon_2.png'
import pok3 from '../images/pokemon/pokemon_3.png'
import pok4 from '../images/pokemon/pokemon_4.png'
import pok5 from '../images/pokemon/pokemon_5.png'
import pok6 from '../images/pokemon/pokemon_6.png'
import pok7 from '../images/pokemon/pokemon_7.png'
import pok8 from '../images/pokemon/pokemon_8.png'
import pok9 from '../images/pokemon/pokemon_9.png'
import pok10 from '../images/pokemon/pokemon_10.png'
import pok11 from '../images/pokemon/pokemon_11.png'
import pok12 from '../images/pokemon/pokemon_12.png'
import pok19 from '../images/pokemon/pokemon_19.png'
import pok20 from '../images/pokemon/pokemon_20.png'
import pok23 from '../images/pokemon/pokemon_23.png'
import pok24 from '../images/pokemon/pokemon_24.png'
import pok25 from '../images/pokemon/pokemon_25.png'
import pok26 from '../images/pokemon/pokemon_26.png'
import pok39 from '../images/pokemon/pokemon_39.png'
import pok40 from '../images/pokemon/pokemon_40.png'
import pok50 from '../images/pokemon/pokemon_50.png'
import pok51 from '../images/pokemon/pokemon_51.png'
import pok52 from '../images/pokemon/pokemon_52.png'
import pok53 from '../images/pokemon/pokemon_53.png'
import pok54 from '../images/pokemon/pokemon_54.png'
import pok55 from '../images/pokemon/pokemon_55.png'
import pok56 from '../images/pokemon/pokemon_56.png'
import pok57 from '../images/pokemon/pokemon_57.png'
import pok58 from '../images/pokemon/pokemon_58.png'
import pok59 from '../images/pokemon/pokemon_59.png'
import pok69 from '../images/pokemon/pokemon_69.png'
import pok70 from '../images/pokemon/pokemon_70.png'
import pok71 from '../images/pokemon/pokemon_71.png'
import pok74 from '../images/pokemon/pokemon_74.png'
import pok75 from '../images/pokemon/pokemon_75.png'
import pok76 from '../images/pokemon/pokemon_76.png'
import pok79 from '../images/pokemon/pokemon_79.png'
import pok80 from '../images/pokemon/pokemon_80.png'
import pok81 from '../images/pokemon/pokemon_81.png'
import pok82 from '../images/pokemon/pokemon_82.png'
import pok84 from '../images/pokemon/pokemon_84.png'
import pok85 from '../images/pokemon/pokemon_85.png'
import pok92 from '../images/pokemon/pokemon_92.png'
import pok93 from '../images/pokemon/pokemon_93.png'
import pok94 from '../images/pokemon/pokemon_94.png'
import pok104 from '../images/pokemon/pokemon_104.png'
import pok105 from '../images/pokemon/pokemon_105.png'
import pok115 from '../images/pokemon/pokemon_115.png'
import pok127 from '../images/pokemon/pokemon_127.png'
import pok132 from '../images/pokemon/pokemon_132.png'
import pok133 from '../images/pokemon/pokemon_133.png'
import pok134 from '../images/pokemon/pokemon_134.png'
import pok135 from '../images/pokemon/pokemon_135.png'
import pok136 from '../images/pokemon/pokemon_136.png'
import pok152 from '../images/pokemon/pokemon_152.png'
import pok153 from '../images/pokemon/pokemon_153.png'
import pok154 from '../images/pokemon/pokemon_154.png'
import pok155 from '../images/pokemon/pokemon_155.png'
import pok156 from '../images/pokemon/pokemon_156.png'
import pok157 from '../images/pokemon/pokemon_157.png'
import pok158 from '../images/pokemon/pokemon_158.png'
import pok159 from '../images/pokemon/pokemon_159.png'
import pok160 from '../images/pokemon/pokemon_160.png'
import pok172 from '../images/pokemon/pokemon_172.png'
import pok174 from '../images/pokemon/pokemon_174.png'
import pok175 from '../images/pokemon/pokemon_175.png'
import pok176 from '../images/pokemon/pokemon_176.png'
import pok179 from '../images/pokemon/pokemon_179.png'
import pok180 from '../images/pokemon/pokemon_180.png'
import pok181 from '../images/pokemon/pokemon_181.png'
import pok185 from '../images/pokemon/pokemon_185.png'
import pok196 from '../images/pokemon/pokemon_196.png'
import pok197 from '../images/pokemon/pokemon_197.png'
import pok199 from '../images/pokemon/pokemon_199.png'
import pok202 from '../images/pokemon/pokemon_202.png'
import pok214 from '../images/pokemon/pokemon_214.png'
import pok228 from '../images/pokemon/pokemon_228.png'
import pok229 from '../images/pokemon/pokemon_229.png'
import pok246 from '../images/pokemon/pokemon_246.png'
import pok247 from '../images/pokemon/pokemon_247.png'
import pok248 from '../images/pokemon/pokemon_248.png'
import pok287 from '../images/pokemon/pokemon_287.png'
import pok288 from '../images/pokemon/pokemon_288.png'
import pok289 from '../images/pokemon/pokemon_289.png'
import pok302 from '../images/pokemon/pokemon_302.png'
import pok316 from '../images/pokemon/pokemon_316.png'
import pok317 from '../images/pokemon/pokemon_317.png'
import pok333 from '../images/pokemon/pokemon_333.png'
import pok334 from '../images/pokemon/pokemon_334.png'
import pok359 from '../images/pokemon/pokemon_359.png'
import pok360 from '../images/pokemon/pokemon_360.png'
import pok363 from '../images/pokemon/pokemon_363.png'
import pok364 from '../images/pokemon/pokemon_364.png'
import pok365 from '../images/pokemon/pokemon_365.png'
import pok438 from '../images/pokemon/pokemon_438.png'
import pok447 from '../images/pokemon/pokemon_447.png'
import pok448 from '../images/pokemon/pokemon_448.png'
import pok453 from '../images/pokemon/pokemon_453.png'
import pok454 from '../images/pokemon/pokemon_454.png'
import pok462 from '../images/pokemon/pokemon_462.png'
import pok468 from '../images/pokemon/pokemon_468.png'
import pok470 from '../images/pokemon/pokemon_470.png'
import pok471 from '../images/pokemon/pokemon_471.png'
import pok700 from '../images/pokemon/pokemon_700.png'

interface Props {
    id: number
    size?: string
}

const PokemonIcon: FC<Props> = ({ id, size }) => {
    let icon;
    switch (id) {
        case 1:
            icon = pok1;
            break;
        case 2:
            icon = pok2;
            break;
        case 3:
            icon = pok3;
            break;
        case 4:
            icon = pok4;
            break;
        case 5:
            icon = pok5;
            break;
        case 6:
            icon = pok6;
            break;
        case 7:
            icon = pok7;
            break;
        case 8:
            icon = pok8;
            break;
        case 9:
            icon = pok9;
            break;
        case 10:
            icon = pok10;
            break;
        case 11:
            icon = pok11;
            break;
        case 12:
            icon = pok12;
            break;
        case 19:
            icon = pok19;
            break;
        case 20:
            icon = pok20;
            break;
        case 23:
            icon = pok23;
            break;
        case 24:
            icon = pok24;
            break;
        case 25:
            icon = pok25;
            break;
        case 26:
            icon = pok26;
            break;
        case 39:
            icon = pok39;
            break;
        case 40:
            icon = pok40;
            break;
        case 50:
            icon = pok50;
            break;
        case 51:
            icon = pok51;
            break;
        case 52:
            icon = pok52;
            break;
        case 53:
            icon = pok53;
            break;
        case 54:
            icon = pok54;
            break;
        case 55:
            icon = pok55;
            break;
        case 56:
            icon = pok56;
            break;
        case 57:
            icon = pok57;
            break;
        case 58:
            icon = pok58;
            break;
        case 59:
            icon = pok59;
            break;
        case 69:
            icon = pok69;
            break;
        case 70:
            icon = pok70;
            break;
        case 71:
            icon = pok71;
            break;
        case 74:
            icon = pok74;
            break;
        case 75:
            icon = pok75;
            break;
        case 76:
            icon = pok76;
            break;
        case 79:
            icon = pok79;
            break;
        case 80:
            icon = pok80;
            break;
        case 81:
            icon = pok81;
            break;
        case 82:
            icon = pok82;
            break;
        case 84:
            icon = pok84;
            break;
        case 85:
            icon = pok85;
            break;
        case 92:
            icon = pok92;
            break;
        case 93:
            icon = pok93;
            break;
        case 94:
            icon = pok94;
            break;
        case 104:
            icon = pok104;
            break;
        case 105:
            icon = pok105;
            break;
        case 115:
            icon = pok115;
            break;
        case 127:
            icon = pok127;
            break;
        case 132:
            icon = pok132;
            break;
        case 133:
            icon = pok133;
            break;
        case 134:
            icon = pok134;
            break;
        case 135:
            icon = pok135;
            break;
        case 136:
            icon = pok136;
            break;
        case 152:
            icon = pok152;
            break;
        case 153:
            icon = pok153;
            break;
        case 154:
            icon = pok154;
            break;
        case 155:
            icon = pok155;
            break;
        case 156:
            icon = pok156;
            break;
        case 157:
            icon = pok157;
            break;
        case 158:
            icon = pok158;
            break;
        case 159:
            icon = pok159;
            break;
        case 160:
            icon = pok160;
            break;
        case 172:
            icon = pok172;
            break;
        case 174:
            icon = pok174;
            break;
        case 175:
            icon = pok175;
            break;
        case 176:
            icon = pok176;
            break;
        case 179:
            icon = pok179;
            break;
        case 180:
            icon = pok180;
            break;
        case 181:
            icon = pok181;
            break;
        case 185:
            icon = pok185;
            break;
        case 196:
            icon = pok196;
            break;
        case 197:
            icon = pok197;
            break;
        case 199:
            icon = pok199;
            break;
        case 202:
            icon = pok202;
            break;
        case 214:
            icon = pok214;
            break;
        case 228:
            icon = pok228;
            break;
        case 229:
            icon = pok229;
            break;
        case 246:
            icon = pok246;
            break;
        case 247:
            icon = pok247;
            break;
        case 248:
            icon = pok248;
            break;
        case 287:
            icon = pok287;
            break;
        case 288:
            icon = pok288;
            break;
        case 289:
            icon = pok289;
            break;
        case 302:
            icon = pok302;
            break;
        case 316:
            icon = pok316;
            break;
        case 317:
            icon = pok317;
            break;
        case 333:
            icon = pok333;
            break;
        case 334:
            icon = pok334;
            break;
        case 359:
            icon = pok359;
            break;
        case 360:
            icon = pok360;
            break;
        case 363:
            icon = pok363;
            break;
        case 364:
            icon = pok364;
            break;
        case 365:
            icon = pok365;
            break;
        case 438:
            icon = pok438;
            break;
        case 447:
            icon = pok447;
            break;
        case 448:
            icon = pok448;
            break;
        case 453:
            icon = pok453;
            break;
        case 454:
            icon = pok454;
            break;
        case 462:
            icon = pok462;
            break;
        case 468:
            icon = pok468;
            break;
        case 470:
            icon = pok470;
            break;
        case 471:
            icon = pok471;
            break;
        case 700:
            icon = pok700;
            break;
        default:
            icon = pok1;
    }

    return (
        <Box p={0} display="flex" position="relative" top="0px" maxWidth={size} justifyContent="center" alignItems="center">
            <img src={icon} width="100%" height="auto" alt="" />
        </Box>
    )
}

export default PokemonIcon