enum ThemeType {
    Light = 'light',
    Dark = 'dark',
}
const breakpoints = ['0', '600px', '960px', '1280px', '1920px']

// breakpoints.xs = breakpoints[0]
// breakpoints.sm = breakpoints[1]
// breakpoints.md = breakpoints[2]
// breakpoints.lg = breakpoints[3]
// breakpoints.xl = breakpoints[4]

const fontSizes = [8, 10, 12, 14, 16, 18, 20, 24, 32, 40, 48, 56]
const space = [0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 64, 128]
const radii = {
    none: 0,
    small: 4,
    medium: 8,
    large: 12,
    xlarge: 16,
    xxlarge: 20,
}

const lightTheme = {
    colors: {
        // body: '#f3f6f7',
        body: '#F5F5F5',
        heading: '#010005',
        text: '#212121',
        white: '#fff',
        whiteAlways: '#fff',
        black: '#000e1a',
        blackAlways: '#000e1a',
        blue: ['#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6', '#42A5F5', '#2196F3', '#1E88E5'],
        green: '#37C1C1',
        navy: '#004175',
        orange: '#FA7248',
        purple: '#B5A9FF',
        red: '#DC1826',
        yellow: '#F2B71F',
        // red: '#D50000',
        // darkRed: '#AA0000',
        darkRed: '#AE131E',
        pokemon: 'yellow',
        // primary: '#317BF3',
        primary: '#1976D2',
        danger: '#DC1826',
        grey: ['#F5F5F5', '#EEEEEE', '#E0E0E0', '#BDBDBD', '#9E9E9E', '#757575', '#616161'],
    },
    shadows: {
        card: "0px 2px 3px rgba(0, 0, 0, .15)",
        modal: "0px 0px 10px 2px rgba(0, 0, 0, .2)"
    },
    breakpoints,
    fontSizes,
    space,
    radii,
    buttons: {
        primary: {
            bg: 'red',
            color: 'white',
            borderColor: 'red',
        },
        secondary: {
            color: 'white',
            bg: 'black',
            '&:active': {
                bg: 'grey.5',
            },
        },
        filter: {
            display: 'inline-block',
            fontSize: 1,
            padding: 2,
            lineHeight: 1,
            color: 'primary',
            borderRadius: '20px',
            borderColor: 'primary',
            backgroundColor: 'transparent',
            fill: 'primary',
        },
        filterSelected: {
            display: 'inline-block',
            fontSize: 1,
            padding: 2,
            lineHeight: 1,
            color: 'white',
            borderRadius: '20px',
            borderColor: 'primary',
            backgroundColor: 'primary',
            fill: 'white',
        },
        none: {
            background: 'transparent',
            border: 'none',
        },
    },
}

const darkTheme = {
    colors: {
        body: '#010005',
        heading: '#ffffff',
        text: '#F5F5F5',
        white: '#212121',
        whiteAlways: '#fff',
        black: '#ffffff',
        blackAlways: '#000e1a',
        blue: ['#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6', '#42A5F5', '#2196F3', '#1E88E5'],
        green: '#33ABA9',
        navy: '#004175',
        orange: '#F4523B',
        purple: '#A595FF',
        red: '#DC1826',
        yellow: '#E7A300',
        darkRed: '#AA0000',
        pokemon: 'yellow',
        primary: '#317BF3',
        danger: '#DC1826',
        grey: [
            '#111111',
            '#212121',
            '#424242',
            '#616161',
            '#757575',
            '#9E9E9E',
            '#BDBDBD',
            // '#E0E0E0',
        ],
    },
    shadows: {
        card: "0px 2px 3px rgb(200 200 200 / 25%);",
        modal: "0px 2px 3px 4px rgba(0, 0, 0, .25)"
    },
    breakpoints,
    fontSizes,
    space,
    radii,
    buttons: {
        primary: {
            bg: 'darkRed',
            color: '#ffffff',
            borderColor: 'darkRed',
        },
        secondary: {
            bg: 'grey.0',
            color: 'black',
            borderColor: 'grey.1',
            '&:active': {
                bg: 'grey.1',
            },
        },
        filter: {
            display: 'inline-block',
            fontSize: 1,
            padding: 2,
            lineHeight: 1,
            borderRadius: '20px',
            color: 'primary',
            borderColor: 'primary',
            backgroundColor: 'transparent',
            fill: 'primary',
        },
        filterSelected: {
            display: 'inline-block',
            fontSize: 1,
            padding: 2,
            lineHeight: 1,
            color: 'white',
            borderRadius: '20px',
            borderColor: 'primary',
            backgroundColor: 'primary',
            fill: 'white',
        },
        none: {
            background: 'transparent',
            border: 'none',
        },
    },
}

export type ThemeProps = typeof lightTheme

export { darkTheme, lightTheme, ThemeType }
