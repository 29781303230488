import { FC } from "react"
import SEO from "../components/seo"
import Box from "../components/box"
import Heading from "../components/heading"
import Text from "../components/text"
import { allEvents } from "../data/events"
import banner from '../images/good-sleep-event-banner.jpg'
import { Link } from "react-router-dom"


const Events: FC = () => {
    return (
        <>
            <SEO title={`Events`} />
            <Box>
                <Heading as="h1" fontSize={7} mt={4} mb={5}>Events</Heading>
                {
                    allEvents.map(event => (
                        <Link to={`/events/${event.slug}`} style={{ textDecoration: 'none' }}>
                            <Box display="flex" flexDirection="column" border="1px solid" borderColor="grey.2" borderRadius="medium" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                                <img alt="Good Day Sleep Banner" src={banner} width="100%" style={{ margin: "0 auto", display: "block", maxWidth: "400px" }} />

                                <Box p={4} pl={4} flex="1">
                                    <Heading as="h1" fontSize={6} mb={2}>{event.name}</Heading>
                                    <Text fontSize={4} my={2}>{event.startDate} - {event.endDate}</Text>
                                </Box>
                            </Box>
                        </Link>
                    ))
                }
            </Box>
        </>
    )
}

export default Events