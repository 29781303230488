import React, { FC } from "react"
import { ThemeProvider } from 'styled-components';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { Outlet } from "react-router-dom";

import Wrapper from "./containers/wrapper"
import Container from "./containers/container"
import Header from "./components/header/header"
import { darkTheme, lightTheme, ThemeType } from './theme'
import { useDarkMode } from "./hooks/useDarkMode"
import GlobalStyles from "./containers/global";

const firebaseConfig = {
  apiKey: "AIzaSyCpmHbOQhO0qtISF0kD7nofxiHlyanTMus",
  authDomain: "pokeguide-sleep.firebaseapp.com",
  projectId: "pokeguide-sleep",
  storageBucket: "pokeguide-sleep.appspot.com",
  messagingSenderId: "536010888370",
  appId: "1:536010888370:web:efbf995c5a58f4348ca5ab",
  measurementId: "G-27C577FP71",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
  "feature_request_link": ""
};

const IndexPage: FC = () => {
  const [theme, toggleTheme, componentMounted] = useDarkMode()
  const themeMode = theme === ThemeType.Light ? lightTheme : darkTheme

  if (!componentMounted) {
    return <div />
  }

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles theme={themeMode} />
      <div id="app-wrapper">
        <Header themeMode={theme} toggleTheme={toggleTheme} siteTitle={`PokéGuide`}>
          {/* <HeaderLink to="/tools">
          More
        </HeaderLink> */}
        </Header>
        <Container py={6}>
          <Wrapper>
            <Outlet />
          </Wrapper >
        </Container>
      </div>
    </ThemeProvider>
  )
}

export default IndexPage
