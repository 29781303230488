import styled from 'styled-components'
import {
  layout,
  space,
  SpaceProps,
  LayoutProps,
  flexbox,
  FlexboxProps,
  typography,
  border,
  TypographyProps,
  BorderProps,
  color,
  ColorProps,
  position,
  PositionProps,
} from '@techstack/styled-system'

export interface InputProps
  extends SpaceProps,
  LayoutProps,
  FlexboxProps,
  TypographyProps,
  BorderProps,
  ColorProps,
  PositionProps {
}

const Select = styled.select<InputProps>`
  color: ${({ theme }) => theme.colors.black};
  /* display: block; */
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  padding: ${({ theme }) => theme.space[4]}px;
  border-radius: ${({ theme }) => theme.radii.small}px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[3]};
  cursor: pointer;
  user-select: none;
  line-height: 1;
  font-weight: bold;

  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAA2CAYAAABHno4jAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL0SURBVHgB5dxdUhpBEMDx7pHkmSOQE4QcISfQnEBvIDxaYlwk6CPLCdQbJCdIPEG4QTgC78p2pqG1NpYEWOajZ/m/COzuzPCrlVqoAoRXZdmoOX8PJwB4CARt+1CTHyeACSL8OkAaZ2fdKexx2c2oVYA5Lgo6QoAWLI1mgDABovvBeffu9TFYvtMbjI7Q4K0cuDK7T3Z1dtqHPezyOh8VBJ01u03tqdkvg79A927Gl1hQBhvGZ3jjkT5nWXcGexD/pz+9w58WrL3pMeUTcgF9MRyd2Ju3sGX7gl0F+Tkq6Mu3i+73JfR1/seqtaBCdcfeBVmaHTzSB7M4mysic7wAXggvCGqWA2RucXFhAPEYdqyO2I6QJTw0SC4Gqhe2W2Tg19e2oTWXcttUB2znyMuaBvlC22EpY3tCXmSKxcW121LE9olsx5wY+wLyAzyUErZPZK4AejANA3fgqRSwfSNz1jg3/AGRffcyBk9pxg6BbD/W6LPxy2cdX4f5b/I4obZ3kEGQ7XO+Ou984tvm+UFjEXgDeErTmR0KmU1L9/9dQGEXUOczOyRy+TniWwupK3YsZHn87QXVDTsmsmxbvbC6YMdGlu2rqwO2BmTZ5/+ljK0FWfZbX4rYmpBl381KCVsbsuy/eSlga0SWY7ZLM7ZWZDlu+zRia0aWY6ulCVs7shxfPQ3YKSDLGLsVEzsVZBln92Jgp4QsY7kpJDbfTglZxnNXKGz+mxKyjOm2ENg+84Es47ovVWxfyDK2n1LD9oks4/srFWzfyDKH37Rjh0CWefynFTsUsswVJm3YIZFlvnBpwQ6NLHOGLTZ2DGSZN3yxsGMhy9xxCo0dE1nmj1co7NjIsoa4+cbWgCzriJ8vbC3IshYducbWhMypgeZcYWtD5lRBc7tia0Tm1EFzVbG1InMGFMZQ236nhojutSJzKs/ocr3BKEeDp6u281es50T9Ya+bg+LUQ3P8Y1FPBXQQ8SP/GgPh4vvrU5rTQ2MOudazuNxfjNj2tnnNZEIAAAAASUVORK5CYII=);
  background-position: 92%;
  background-size: 8%;
  background-repeat: no-repeat;

  transition: transform 0.1s ease-out, background 0.1s ease-out, color 0.15s ease-out;
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  ${border}
  ${color}
  ${position}
`

export default Select
