import { FC } from "react"
import Box from "./box"

import { SLEEP_TYPE } from "../data/islands"

interface Props {
    type: SLEEP_TYPE
}

const SleepTypeIcon: FC<Props> = ({ type }) => {
    let color
    switch (type) {
        case SLEEP_TYPE.Dozing:
            color = "#FCED83";
            break;
        case SLEEP_TYPE.Snoozing:
            color = "#A3F8FD";
            break;
        case SLEEP_TYPE.Slumbering:
            color = "#5586F4";
            break;
        default:
            color = "grey";
    }

    return (
        <Box style={{ backgroundColor: color }} position="relative" top="0px" width="10px" height="10px" borderRadius="10px" border="1px solid" borderColor="grey.2">
        </Box>
    )
}

export default SleepTypeIcon