import { FC } from "react"
import SEO from "../components/seo"
import Box from "../components/box"
import Heading from "../components/heading"
import Text from "../components/text"
import banner from '../images/banner.png'
import { remoteConfig } from "../App"
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";

fetchAndActivate(remoteConfig)
    .then(() => {
    })
    .catch((err) => {
    });

const requestUrl = getValue(remoteConfig, "feature_request_link");

const About: FC = () => {
    return (
        <>
            <SEO title={`About`} />
            <Box>
                <img alt="Pokemon Sleep Logo" src={banner} width="80%" style={{margin: "0 auto", display: "block", maxWidth: "400px"}} />
                <Heading as="h1" fontSize={7} mt={4} mb={5}>About</Heading>
                <Text>Rest your very best! Pokémon Sleep is a sleep app that gives you something fun to look forward to when you wake up in the morning.</Text>
                <Text><strong>PokéGuide Sleep</strong> is a web app created to help you become the very best when playing Pokemon Sleep. It aims to be your one stop show when looking to excel at playing Pokémon Sleep.</Text>
                <Text><strong>PokéGuide Sleep</strong> is currently in development, if you have suggestions or ideas on how to improve the usefulness of this app please feel free to submit a feature request here (TBD)</Text>
                <a href={requestUrl.asString()}>Submit feature request</a>
            </Box>
        </>
    )
}

export default About