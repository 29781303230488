import styled from 'styled-components'
import {
  layout,
  space,
  variant,
  SpaceProps,
  LayoutProps,
  flexbox,
  FlexboxProps,
  typography,
  border,
  TypographyProps,
  BorderProps,
  compose,
  color,
  ColorProps,
  position,
  PositionProps,
} from '@techstack/styled-system'

export interface ButtonProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    TypographyProps,
    BorderProps,
    ColorProps,
    PositionProps {
  variant?: 'primary' | 'secondary' | 'filter' | 'filterSelected' | 'none'
}

const Button = styled.button<ButtonProps>`
  color: ${({ theme }) => theme.colors.black};
  display: block;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  padding: ${({ theme }) => theme.space[4]}px;
  border-radius: ${({ theme }) => theme.radii.small}px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[3]};
  cursor: pointer;
  user-select: none;
  line-height: 1;
  font-weight: bold;

  transition: transform 0.1s ease-out, background 0.1s ease-out, color 0.15s ease-out;

  &:active {
    transform: scaleX(0.98);
    transition: transform 0.15s ease-in, background 0.1s ease-in;
  }
  ${variant({
    scale: 'buttons',
    variants: {
      primary: {
        bg: 'red',
        color: 'white',
        borderColor: 'red',
      },
      secondary: {
        color: 'black',
        bg: 'white',
        '&:active': {
          bg: 'grey.1',
        },
      },
      filter: {
        display: 'inline-block',
        fontSize: 0,
      },
      none: {
        color: 'black',
        background: 'transparent',
        border: 'none',
        fontWeight: 'bold'
      },
    },
  })}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  ${border}
  ${color}
  ${position}
`

interface ButtonLinkProps extends SpaceProps, FlexboxProps, TypographyProps, ColorProps {}

export const ButtonLink = styled.button<ButtonLinkProps>`
  border: none;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  padding: ${({ theme }) => theme.space[2]}px;
  background-color: transparent;
  outline: none;
  color: ${({ theme }) => theme.colors.black};
  &:active {
    background-color: ${({ theme }) => theme.colors.grey[2]};
  }

  ${compose(space, flexbox, typography, color)}
`

export default Button
