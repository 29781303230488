import { FC } from "react"
import Box from "./box"
import { styled } from "styled-components"
import Heading from "./heading"
import Button from "./button"

const ModalContainer = styled(Box)`
  position: absolute;
  left: 50%;
  right: 50%;
  top: 10%;
  width: 80%;
  transform: translateX(-50%);
  max-width: 400px;
  z-index: 1000;
  animation: fadeIn .25s ease-out;
  @keyframes fadeIn {
    from {
        transform: translateX(-50%) translateY(30px) scale(.98);
        opacity: 0;
    }
    to {
        transform: translateX(-50%) translateY(0) scale(1);
        opacity: 1;
    }
  }
`

interface Props {
    children: React.ReactNode,
    title: string,
    isOpen: boolean,
    close: () => void
}

const Modal: FC<Props> = ({ title, children, isOpen, close }) => {
    if (isOpen) {
        return (
            <ModalContainer bg="grey.0" border="1px solid" borderColor="grey.2" p={0} borderRadius="medium" display="flex" flexDirection="column" position="relative" top="0px" justifyContent="center" alignItems="center" overflow="hidden" boxShadow="modal">
                <Box bg="grey.0" width="100%" p={2} pl={3} display="flex" justifyContent="space-between" alignItems="center">
                    <Heading as="h2" fontSize={4} m={0}>{title}</Heading>
                    <Button onClick={() => close()} display="inline-block" width="auto" p={3} bg="transparent" border="none" color="black" fontSize={6}>{String.fromCharCode(0x2715)}</Button>
                </Box>
                <Box bg="grey.1" width="100%" p={3}>
                    {children}
                </Box>
                {/* <Box bg="grey.0" width="100%" p={3}>
                    Footer
                </Box> */}
            </ModalContainer>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Modal