import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Recipes from './routes/recipes';
import About from './routes/about';
import Events from './routes/events';
import Event from './routes/event';
import Home from './routes/home';
import Islands from './routes/islands';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "recipes/:recipeType?",
        element: <Recipes />,
      },
      {
        path: "about/",
        element: <About />,
      },
      {
        path: "events/",
        element: <Events />,
      },
      {
        path: "events/:eventSlug",
        element: <Event />,
      },
      {
        path: "islands/",
        element: <Islands />,
      },
      {
        path: "islands/:islandSlug",
        element: <Islands />,
      },
    ]
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
