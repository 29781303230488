import { styled } from 'styled-components'
import { space, layout, color, ColorProps, SpaceProps, LayoutProps, flexbox, FlexboxProps, border, BorderProps, position, PositionProps, compose, grid, GridProps, typography, TypographyProps, shadow, ShadowProps } from '@techstack/styled-system'

export interface BoxProps extends SpaceProps, LayoutProps, FlexboxProps, BorderProps, PositionProps, GridProps, TypographyProps, ColorProps, ShadowProps  {}

const Box = styled.div<BoxProps>(
  compose(
    space,
    layout,
    color,
    flexbox,
    border,
    position,
    grid,
    typography,
    shadow
  )
)

export default Box