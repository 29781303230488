import styled from 'styled-components'
import { space, layout, color, SpaceProps, LayoutProps, flexbox, FlexboxProps, typography, TypographyProps, compose } from '@techstack/styled-system'

interface Props extends SpaceProps, LayoutProps, FlexboxProps, TypographyProps {}

const Heading = styled.h1<Props>`
  color: ${({ theme }) => theme.colors.heading};
  letter-spacing: -.015em;
  font-weight: 800;
  margin: 0;

  transition: color .15s ease-out;

  ${compose(
    space,
    layout,
    color,
    flexbox,
    typography,
  )}
`

export default Heading;