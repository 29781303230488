import { Helmet } from "react-helmet"

interface Props {
    description?: string
    lang?: string
    meta?: any[]
    title: string
}

function SEO({ lang = 'en', meta = [], title, description }: Props) {

    const metaDescription = description || 'Guide for all things Pokèmon Sleep'
    const defaultTitle = 'PokéGuide Sleep'

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: `Larry Bucio`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    )
}

export default SEO
