import { FC } from "react"
import Box from "./box"

import { SNORLAX_RATING } from "../data/islands"

import basic from '../images/icons/sleep-rank/rank_pokeball.png'
import great from '../images/icons/sleep-rank/rank_greatball.png'
import ultra from '../images/icons/sleep-rank/rank_ultraball.png'
import master from '../images/icons/sleep-rank/rank_masterball.png'

interface Props {
    type: SNORLAX_RATING
    size?: string
}

const SleepRankIcon: FC<Props> = ({ type, size }) => {
    let icon;
    switch (type) {
        case SNORLAX_RATING.Basic1:
        case SNORLAX_RATING.Basic2:
        case SNORLAX_RATING.Basic3:
        case SNORLAX_RATING.Basic4:
        case SNORLAX_RATING.Basic5:
            icon = basic;
            break;
        case SNORLAX_RATING.Great1:
        case SNORLAX_RATING.Great2:
        case SNORLAX_RATING.Great3:
        case SNORLAX_RATING.Great4:
        case SNORLAX_RATING.Great5:
            icon = great;
            break;
        case SNORLAX_RATING.Ultra1:
        case SNORLAX_RATING.Ultra2:
        case SNORLAX_RATING.Ultra3:
        case SNORLAX_RATING.Ultra4:
        case SNORLAX_RATING.Ultra5:
            icon = ultra;
            break;
        case SNORLAX_RATING.Master1:
        case SNORLAX_RATING.Master2:
        case SNORLAX_RATING.Master3:
        case SNORLAX_RATING.Master4:
        case SNORLAX_RATING.Master5:
        case SNORLAX_RATING.Master6:
        case SNORLAX_RATING.Master7:
        case SNORLAX_RATING.Master8:
        case SNORLAX_RATING.Master9:
        case SNORLAX_RATING.Master10:
        case SNORLAX_RATING.Master11:
        case SNORLAX_RATING.Master12:
        case SNORLAX_RATING.Master13:
        case SNORLAX_RATING.Master14:
        case SNORLAX_RATING.Master15:
        case SNORLAX_RATING.Master16:
        case SNORLAX_RATING.Master17:
        case SNORLAX_RATING.Master18:
        case SNORLAX_RATING.Master19:
        case SNORLAX_RATING.Master20:
            icon = master;
            break;
        default:
            icon = basic;
    }

    return (
        <Box bg="transparent" p={3} display="flex" position="relative" top="0px" maxWidth={size} justifyContent="center" alignItems="center">
            <img src={icon} width="100%" height="auto" alt="" />
        </Box>
    )
}

export default SleepRankIcon