export enum ISLAND {
    Greengrass = 'Greengrass',
    Cyan = 'Cyan',
    Taupe = 'Taupe'
}

export enum SLEEP_TYPE {
    Dozing = 'Dozing',
    Snoozing = 'Snoozing',
    Slumbering = 'Slumbering'
}

export enum SNORLAX_RATING {
    Basic1 = 0,
    Basic2 = 1,
    Basic3 = 2,
    Basic4 = 3,
    Basic5 = 4,
    Great1 = 5,
    Great2 = 6,
    Great3 = 7,
    Great4 = 8,
    Great5 = 9,
    Ultra1 = 10,
    Ultra2 = 11,
    Ultra3 = 12,
    Ultra4 = 13,
    Ultra5 = 14,
    Master1 = 15,
    Master2 = 16,
    Master3 = 17,
    Master4 = 18,
    Master5 = 19,
    Master6 = 20,
    Master7 = 21,
    Master8 = 22,
    Master9 = 23,
    Master10 = 24,
    Master11 = 25,
    Master12 = 26,
    Master13 = 27,
    Master14 = 28,
    Master15 = 29,
    Master16 = 30,
    Master17 = 31,
    Master18 = 32,
    Master19 = 33,
    Master20 = 34,
}

export const getSnorlaxLevel = (type: SNORLAX_RATING) => {
    switch (type) {
        case SNORLAX_RATING.Basic1:
            return 'Basic 1'
        case SNORLAX_RATING.Basic2:
            return 'Basic 2'
        case SNORLAX_RATING.Basic3:
            return 'Basic 3'
        case SNORLAX_RATING.Basic4:
            return 'Basic 4'
        case SNORLAX_RATING.Basic5:
            return 'Basic 5'
        case SNORLAX_RATING.Great1:
            return 'Great 1'
        case SNORLAX_RATING.Great2:
            return 'Great 2'
        case SNORLAX_RATING.Great3:
            return 'Great 3'
        case SNORLAX_RATING.Great4:
            return 'Great 4'
        case SNORLAX_RATING.Great5:
            return 'Great 5'
        case SNORLAX_RATING.Ultra1:
            return 'Ultra 1'
        case SNORLAX_RATING.Ultra2:
            return 'Ultra 2'
        case SNORLAX_RATING.Ultra3:
            return 'Ultra 3'
        case SNORLAX_RATING.Ultra4:
            return 'Ultra 4'
        case SNORLAX_RATING.Ultra5:
            return 'Ultra 5'
        case SNORLAX_RATING.Master1:
            return 'Master 1'
        case SNORLAX_RATING.Master2:
            return 'Master 2'
        case SNORLAX_RATING.Master3:
            return 'Master 3'
        case SNORLAX_RATING.Master4:
            return 'Master 4'
        case SNORLAX_RATING.Master5:
            return 'Master 5'
        case SNORLAX_RATING.Master6:
            return 'Master 6'
        case SNORLAX_RATING.Master7:
            return 'Master 7'
        case SNORLAX_RATING.Master8:
            return 'Master 8'
        case SNORLAX_RATING.Master9:
            return 'Master 9'
        case SNORLAX_RATING.Master10:
            return 'Master 10'
        case SNORLAX_RATING.Master11:
            return 'Master 11'
        case SNORLAX_RATING.Master12:
            return 'Master 12'
        case SNORLAX_RATING.Master13:
            return 'Master 13'
        case SNORLAX_RATING.Master14:
            return 'Master 14'
        case SNORLAX_RATING.Master15:
            return 'Master 15'
        case SNORLAX_RATING.Master16:
            return 'Master 16'
        case SNORLAX_RATING.Master17:
            return 'Master 17'
        case SNORLAX_RATING.Master18:
            return 'Master 18'
        case SNORLAX_RATING.Master19:
            return 'Master 19'
        case SNORLAX_RATING.Master20:
            return 'Master 20'
    }
}


const greengrass = [
    {
        pokemonId: 0, sleepType: SLEEP_TYPE.Dozing, snorlaxRating: SNORLAX_RATING.Basic1
    }
]

export const allIslands = [
    ...greengrass
]