import { FC } from "react"
import { FlexboxProps } from "styled-system";
import { Pokemon } from "../data/pokemon";
import Box from "./box";
import Text from "./text";
import PokemonIcon from "./pokemon-icon";
import SleepTypeIcon from "./sleep-type-icon";

interface Props extends FlexboxProps {
    pokemon: Pokemon
}

const PokemonCard: FC<Props> = ({ pokemon, ...props }) => {

    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

    return (
        <Box p={2} mb={3} display="flex" flexDirection="column">
            <Text fontSize={2} fontWeight="bold" textAlign="center" color="grey.6" m={0} mb="-4px" p={0}>#{zeroPad(pokemon.pokemonId, 3)}</Text>
            <Box position="relative">
                <PokemonIcon id={pokemon.pokemonId} size="64px" />
                <Box position="absolute" top="4px" right="4px">
                    <SleepTypeIcon type={pokemon.sleepType} />
                </Box>
            </Box>
        </Box>
    )
}

export default PokemonCard