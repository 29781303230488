import { styled } from "styled-components"

interface Props {
    isVisible: boolean
}

const Overlay = styled.div<Props>`
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, .5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity ease .2s;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    opacity: ${props => props.isVisible ? 1 : 0};
    display: ${props => props.isVisible ? 'block' : 'none'};
`

export default Overlay