import { useEffect, useState } from 'react';
import { ThemeType } from '../theme';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../App';

export const useDarkMode = (): [ThemeType, () => void, boolean] => {
    const DEFAULT_THEME = ThemeType.Dark;
    const [theme, setTheme] = useState(DEFAULT_THEME)
    const [componentMounted, setComponentMounted] = useState(false)

    const setMode = (mode: ThemeType) => {
        window.localStorage.setItem('theme', mode)
        setTheme(mode)
    }

    const toggleTheme = () => {
        if (theme === ThemeType.Light) {
            logEvent(analytics, 'toggled_theme', {
                theme: 'dark'
            })
            setMode(ThemeType.Dark)
        } else {
            setMode(ThemeType.Light)
            logEvent(analytics, 'toggled_theme', {
                theme: 'light'
            })
        }
    }

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme')
        if (localTheme) {
            setTheme(localTheme as ThemeType)
        } else {
            setMode(DEFAULT_THEME)
        }
        setComponentMounted(true)
    }, [])

    return [theme, toggleTheme, componentMounted]
};