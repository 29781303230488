import { FC } from "react"
import SEO from "../components/seo"
import Box from "../components/box"
import Heading from "../components/heading"
import Text from "../components/text"
import { BONUS_TYPE, allEvents } from "../data/events"
import { groupBy } from "../helpers/helpers"
import { useParams } from "react-router-dom"
import BonusIcon from "../components/bonus-icon"
import { DateTime } from "luxon";
import IncenseIcon from "../components/incense-icon"


const Event: FC = () => {
    let { eventSlug } = useParams()
    const event = allEvents.filter(event => event.slug === eventSlug).at(0)

    return (
        <>
            <SEO title={`${event?.name} | Events`} description={event?.description} />
            <Box>
                {
                    event && (
                        <Box>
                            <Heading as="h1">{event.name}</Heading>
                            <Text fontSize={4}>{DateTime.fromFormat(event.startDate, "MM/dd/yyyy").toFormat("MMMM d")} - {DateTime.fromFormat(event.endDate, "MM/dd/yyyy").toFormat("MMMM d")}</Text>
                            <Text fontSize={3}>{event.description}</Text>

                            <Box>
                                <Heading as="h2" fontSize={6} mb={3}>Bonuses:</Heading>
                                {Object.entries(groupBy(event.bonuses, 'date')).map(([date, bonuses]) => (
                                    <Box mt={3} mb={6}>
                                        <Heading as="h3" fontSize={4} mb={3}>{DateTime.fromFormat(date, "MM/dd/yyyy h:mma").toFormat("EEEE, MMM d - t")}</Heading>
                                        {/* @ts-ignore */}
                                        {bonuses.map(bonus => (
                                            <Box display="flex" border="1px solid" borderColor="grey.2" borderRadius="medium" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                                                <BonusIcon type={bonus.type} size={60} />
                                                <Box p={2} pl={4} flex="1">
                                                    <Text>{bonus.name}</Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                ))}

                                <Heading as="h2" fontSize={6} mt={7} mb={3}>Event Tips:</Heading>
                                <Box display="flex" border="1px solid" borderColor="grey.2" borderRadius="medium" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                                    <IncenseIcon type="growth" size="65px" />
                                    <Box p={2} pl={4} flex="1">
                                        <Text m={0}>Growth Incense</Text>
                                        <Text fontSize={2} mt={2} mb={0}>Growth Incense will multiply Sleep EXP</Text>
                                    </Box>
                                </Box>
                                
                                <Heading as="h2" fontSize={6} mt={7} mb={3}>FAQ:</Heading>
                                {event.faq.map(item => (
                                    <Text>{item.answer}</Text>
                                ))}
                            </Box>
                        </Box>
                    )
                }
            </Box>
        </>
    )
}

export default Event