import { ISLAND } from "../data/islands"
import { RECIPE_TYPE } from "../data/recipes"

export const convertURLToRecipeType = (type: string | undefined) => {
    switch (type) {
        case 'desserts':
            return RECIPE_TYPE.Dessert
        case 'curries':
            return RECIPE_TYPE.Curry
        case 'salads':
            return RECIPE_TYPE.Salad
        default:
            return null
    }
}

export const convertURLToIslandType = (type: string | undefined) => {
    switch (type) {
        case 'greengradd':
            return ISLAND.Greengrass
        case 'cyan':
            return ISLAND.Cyan
        case 'taupe':
            return ISLAND.Taupe
        default:
            return null
    }
}

// function groupBy<T>(items: Array<Record<string, T>>, key: string) {
//     return items.reduce((rv, x) => {
//         (rv[x[key]] = rv[x[key]] || []).push(x)
//         return rv;
//     }, {})
// }

export const groupBy = (items: Array<Record<string, any>>, key: string) => {
    return items.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv;
    }, {})
}