export enum BONUS_TYPE {
    DrowsyPower,
    SleepEXP,
    SleepPoints,
}

const goodSleepEvent = {
    name: 'Good Sleep Event',
    slug: 'good-sleep-event-08-2023',
    startDate: '08/30/2023',
    endDate: '09/01/2023',
    description: `The Good Sleep Day event will begin at 4:00 a.m. on Wednesday, August 30! Rest your very best on the night of a full moon!
This event will be held once a month for a total of three days: on the day of the full moon and the days before and after it.
Let’s all try to get lots of sleep during the event and research loads of Pokémon sleep styles!`,
    bonuses: [
        {
            date: '08/30/2023 4:00am',
            name: 'Drowsy Power ×1.5',
            description: '',
            type: BONUS_TYPE.SleepEXP
        },
        {
            date: '08/30/2023 4:00am',
            name: 'Pokémon Sleep EXP ×2',
            description: '',
            type: BONUS_TYPE.SleepEXP
        },
        {
            date: '08/30/2023 4:00am',
            name: 'Bonus Sleep Points +500',
            description: '',
            type: BONUS_TYPE.SleepPoints
        },
        {
            date: '08/31/2023 4:00am',
            name: 'Drowsy Power ×2',
            description: '',
            type: BONUS_TYPE.SleepEXP
        },
        {
            date: '08/31/2023 4:00am',
            name: 'Pokémon Sleep EXP ×3',
            description: '',
            type: BONUS_TYPE.SleepEXP
        },
        {
            date: '08/31/2023 4:00am',
            name: 'Bonus Sleep Points +1000',
            description: '',
            type: BONUS_TYPE.SleepPoints
        },
        {
            date: '09/01/2023 4:00am',
            name: 'Drowsy Power ×1.5',
            description: '',
            type: BONUS_TYPE.SleepEXP
        },
        {
            date: '09/01/2023 4:00am',
            name: 'Pokémon Sleep EXP ×2',
            description: '',
            type: BONUS_TYPE.SleepEXP
        },
        {
            date: '09/01/2023 4:00am',
            name: 'Bonus Sleep Points +500',
            description: '',
            type: BONUS_TYPE.SleepPoints
        },
    ],
    faq: [
        {
            question: '',
            answer: 'Each day of the Good Sleep Day event rolls over at 4:00 a.m'
        },
        {
            question: '',
            answer: 'Event bonuses apply only to sleep data tracked within the event period.'
        },
        {
            question: '',
            answer: 'If you wait to report sleep data tracked before the event begins, and select “Review Later” during the event, event bonuses will not be applied.'
        },
        {
            question: '',
            answer: 'If you wait to report sleep data tracked during the event, and select “Review Later” after the event has ended, event bonuses will be applied.'
        },
        {
            question: '',
            answer: 'You can only receive bonus sleep points once per day. You will not receive bonus sleep points for the second sleep session in a day.'
        },
        {
            question: '',
            answer: 'Event bonuses do not apply to sleep tracking during the tutorial.'
        },
        {
            question: '',
            answer: 'Event bonuses will be triggered regardless of which site you conduct your sleep research in.'
        },
    ]
}

export const allEvents = [goodSleepEvent]