import { FC } from "react"
import Box from "./box"

import growth from '../images/icons/incense/growth.png'

interface Props {
    type: string
    size?: string
}

const IncenseIcon: FC<Props> = ({ type, size }) => {
    let icon;
    switch (type) {
        case "growth":
            icon = growth;
            break;
        default:
            icon = growth;
    }

    return (
        <Box bg="grey.0" p={3} display="flex" position="relative" top="0px" maxWidth={size} justifyContent="center" alignItems="center">
            <img src={icon} width="100%" height="auto" alt="" />
        </Box>
    )
}

export default IncenseIcon