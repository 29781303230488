export enum RECIPE_TYPE {
    Dessert = "dessert",
    Curry = "curry",
    Salad = "salad"
}

export enum INGREDIENT {
    Apple,
    Cacao,
    Egg,
    Ginger,
    Herb,
    Honey,
    Leek,
    Milk,
    Mushroom,
    Oil,
    Potato,
    Sausage,
    SlowpokeTail,
    Soybeans,
    Tomato
}

export enum RECIPE_SORT_TYPE {
    Ingredients = 0,
    Power = 1
}

export enum RECIPE_SORT {
    Power,
    NumberOfIngredients
}

export enum SORT_DIRECTION {
    ASC = 'asc',
    DESC = 'desc'
}

const dessertRecipes = [
    { name: "Mixed Juice", type: RECIPE_TYPE.Dessert, base_power: 0, ingredients: [] },
    { name: "Big Malasada", type: RECIPE_TYPE.Dessert, base_power: 2927, ingredients: [{ name: "Pure Oil", type: INGREDIENT.Oil, quantity: 10 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 7 }, { name: "Honey", type: INGREDIENT.Honey, quantity: 6 }] },
    { name: "Cloud Nine Soy Cake", type: RECIPE_TYPE.Dessert, base_power: 1798, ingredients: [{ name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 8 }, { name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 7 }] },
    { name: "Craft Soda Pop", type: RECIPE_TYPE.Dessert, base_power: 964, ingredients: [{ name: "Honey", type: INGREDIENT.Honey, quantity: 9 }] },
    { name: "Ember Ginger Tea", type: RECIPE_TYPE.Dessert, base_power: 1788, ingredients: [{ name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 9 }, { name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 7 }] },
    { name: "Fancy Apple Juice", type: RECIPE_TYPE.Dessert, base_power: 763, ingredients: [{ name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 8 }] },
    { name: "Fluffy Sweet Potatoes", type: RECIPE_TYPE.Dessert, base_power: 1783, ingredients: [{ name: "Soft Potato", type: INGREDIENT.Potato, quantity: 9 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 5 }] },
    { name: "Huge Power Soy Donuts", type: RECIPE_TYPE.Dessert, base_power: 3213, ingredients: [{ name: "Pure Oil", type: INGREDIENT.Oil, quantity: 9 }, { name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 6 }, { name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 7 }] },
    { name: "Hustle Protein Smoothie", type: RECIPE_TYPE.Dessert, base_power: 3168, ingredients: [{ name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 15 }, { name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 8 }] },
    { name: "Jigglypuff's Fruity Flan", type: RECIPE_TYPE.Dessert, base_power: 7594, ingredients: [{ name: "Honey", type: INGREDIENT.Honey, quantity: 20 }, { name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 15 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 10 }, { name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 10 }] },
    { name: "Lovely Kiss Smoothie", type: RECIPE_TYPE.Dessert, base_power: 4734, ingredients: [{ name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 11 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 9 }, { name: "Honey", type: INGREDIENT.Honey, quantity: 7 }, { name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 8 }] },
    { name: "Lucky Chant Apple Pie", type: RECIPE_TYPE.Dessert, base_power: 1634, ingredients: [{ name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 12 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 4 }] },
    { name: "Neroli's Restorative Tea", type: RECIPE_TYPE.Dessert, base_power: 5065, ingredients: [{ name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 11 }, { name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 15 }, { name: "Tasty Mushroom", type: INGREDIENT.Mushroom, quantity: 9 }] },
    { name: "Stalwart Vegetable Juice", type: RECIPE_TYPE.Dessert, base_power: 1798, ingredients: [{ name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 9 }, { name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 7 }] },
    { name: "Steadfast Ginger Cookies", type: RECIPE_TYPE.Dessert, base_power: 4921, ingredients: [{ name: "Honey", type: INGREDIENT.Honey, quantity: 14 }, { name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 12 }, { name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 5 }, { name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 4 }] },
    { name: "Sweet Scent Chocolate Cake", type: RECIPE_TYPE.Dessert, base_power: 3280, ingredients: [{ name: "Honey", type: INGREDIENT.Honey, quantity: 9 }, { name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 8 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 7 }] },
    { name: "Warm Moomoo Milk", type: RECIPE_TYPE.Dessert, base_power: 727, ingredients: [{ name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 7 }] },
]

const curryRecipes = [
    { name: "Mixed Curry", type: RECIPE_TYPE.Curry, base_power: 0, ingredients: [] },
    { name: "Beanburger Curry", type: RECIPE_TYPE.Curry, base_power: 764, ingredients: [{ name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 7 }] },
    { name: "Bulk Up Bean Curry", type: RECIPE_TYPE.Curry, base_power: 3274, ingredients: [{ name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 12 }, { name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 6 }, { name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 4 }, { name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 4 }] },
    { name: "Dream Eater Butter Curry", type: RECIPE_TYPE.Curry, base_power: 9010, ingredients: [{ name: "Soft Potato", type: INGREDIENT.Potato, quantity: 18 }, { name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 15 }, { name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 12 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 10 }] },
    { name: "Drought Katsu Curry", type: RECIPE_TYPE.Curry, base_power: 1815, ingredients: [{ name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 10 }, { name: "Pure Oil", type: INGREDIENT.Oil, quantity: 5 }] },
    { name: "Egg Bomb Curry", type: RECIPE_TYPE.Curry, base_power: 4523, ingredients: [{ name: "Honey", type: INGREDIENT.Honey, quantity: 12 }, { name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 11 }, { name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 8 }, { name: "Soft Potato", type: INGREDIENT.Potato, quantity: 4 }] },
    { name: "Fancy Apple Curry", type: RECIPE_TYPE.Curry, base_power: 668, ingredients: [{ name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 7 }] },
    { name: "Grilled Tail Curry", type: RECIPE_TYPE.Curry, base_power: 7483, ingredients: [{ name: "Slowpoke Tail", type: INGREDIENT.SlowpokeTail, quantity: 8 }, { name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 25 }] },
    { name: "Hearty Cheeseburger Curry", type: RECIPE_TYPE.Curry, base_power: 1785, ingredients: [{ name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 8 }, { name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 8 }] },
    { name: "Melty Omelette Curry", type: RECIPE_TYPE.Curry, base_power: 2009, ingredients: [{ name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 10 }, { name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 6 }] },
    { name: "Mild Honey Curry", type: RECIPE_TYPE.Curry, base_power: 749, ingredients: [{ name: "Honey", type: INGREDIENT.Honey, quantity: 7 }] },
    { name: "Ninja Curry", type: RECIPE_TYPE.Curry, base_power: 6159, ingredients: [{ name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 15 }, { name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 9 }, { name: "Large Leek", type: INGREDIENT.Leek, quantity: 9 }, { name: "Tasty Mushroom", type: INGREDIENT.Mushroom, quantity: 5 }] },
    { name: "Simple Chowder", type: RECIPE_TYPE.Curry, base_power: 727, ingredients: [{ name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 7 }] },
    { name: "Soft Potato Chowder", type: RECIPE_TYPE.Curry, base_power: 3089, ingredients: [{ name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 10 }, { name: "Soft Potato", type: INGREDIENT.Potato, quantity: 8 }, { name: "Tasty Mushroom", type: INGREDIENT.Mushroom, quantity: 4 }] },
    { name: "Solar Power Tomato Curry", type: RECIPE_TYPE.Curry, base_power: 1943, ingredients: [{ name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 10 }, { name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 5 }] },
    { name: "Spicy Leek Curry", type: RECIPE_TYPE.Curry, base_power: 5900, ingredients: [{ name: "Large Leek", type: INGREDIENT.Leek, quantity: 14 }, { name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 10 }, { name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 8 }] },
    { name: "Spore Mushroom Curry", type: RECIPE_TYPE.Curry, base_power: 4041, ingredients: [{ name: "Tasty Mushroom", type: INGREDIENT.Mushroom, quantity: 14 }, { name: "Soft Potato", type: INGREDIENT.Potato, quantity: 9 }] },
]

const saladRecipes = [
    { name: "Mixed Salad", type: RECIPE_TYPE.Salad, base_power: 0, ingredients: [] },
    { name: "Bean Ham Salad", type: RECIPE_TYPE.Salad, base_power: 873, ingredients: [{ name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 8 }] },
    { name: "Contrary Chocolate Meat Salad", type: RECIPE_TYPE.Salad, base_power: 3558, ingredients: [{ name: "Soothing Cacao", type: INGREDIENT.Cacao, quantity: 14 }, { name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 9 }] },
    { name: "Dazzling Apple Cheese Salad", type: RECIPE_TYPE.Salad, base_power: 2578, ingredients: [{ name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 15 }, { name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 5 }, { name: "Pure Oil", type: INGREDIENT.Oil, quantity: 3 }] },
    { name: "Fancy Apple Salad", type: RECIPE_TYPE.Salad, base_power: 763, ingredients: [{ name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 8 }] },
    { name: "Gluttony Potato Salad", type: RECIPE_TYPE.Salad, base_power: 5040, ingredients: [{ name: "Soft Potato", type: INGREDIENT.Potato, quantity: 14 }, { name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 9 }, { name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 7 }, { name: "Fancy Apple", type: INGREDIENT.Apple, quantity: 6 }] },
    { name: "Heat Wave Tofu Salad", type: RECIPE_TYPE.Salad, base_power: 1976, ingredients: [{ name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 10 }, { name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 6 }] },
    { name: "Immunity Leek Salad", type: RECIPE_TYPE.Salad, base_power: 2658, ingredients: [{ name: "Large Leek", type: INGREDIENT.Leek, quantity: 10 }, { name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 5 }] },
    { name: "Moomoo Caprese Salad", type: RECIPE_TYPE.Salad, base_power: 2856, ingredients: [{ name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 12 }, { name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 6 }, { name: "Pure Oil", type: INGREDIENT.Oil, quantity: 5 }] },
    { name: "Ninja Salad", type: RECIPE_TYPE.Salad, base_power: 10095, ingredients: [{ name: "Large Leek", type: INGREDIENT.Leek, quantity: 15 }, { name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 15 }, { name: "Tasty Mushroom", type: INGREDIENT.Mushroom, quantity: 12 }, { name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 11 }] },
    { name: "Overheat Ginger Salad", type: RECIPE_TYPE.Salad, base_power: 5225, ingredients: [{ name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 17 }, { name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 10 }, { name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 8 }] },
    { name: "Slowpoke Tail Pepper Salad", type: RECIPE_TYPE.Salad, base_power: 8169, ingredients: [{ name: "Slowpoke Tail", type: INGREDIENT.SlowpokeTail, quantity: 10 }, { name: "Fiery Herb", type: INGREDIENT.Herb, quantity: 10 }, { name: "Pure Oil", type: INGREDIENT.Oil, quantity: 15 }] },
    { name: "Snoozy Tomato Salad", type: RECIPE_TYPE.Salad, base_power: 933, ingredients: [{ name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 8 }] },
    { name: "Snow Cloak Caesar Salad", type: RECIPE_TYPE.Salad, base_power: 1774, ingredients: [{ name: "Moomoo Milk", type: INGREDIENT.Milk, quantity: 10 }, { name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 6 }] },
    { name: "Spore Mushroom Salad", type: RECIPE_TYPE.Salad, base_power: 5859, ingredients: [{ name: "Tasty Mushroom", type: INGREDIENT.Mushroom, quantity: 17 }, { name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 8 }, { name: "Pure Oil", type: INGREDIENT.Oil, quantity: 8 }] },
    { name: "Superpower Extreme Salad", type: RECIPE_TYPE.Salad, base_power: 2958, ingredients: [{ name: "Bean Sausage", type: INGREDIENT.Sausage, quantity: 9 }, { name: "Warming Ginger", type: INGREDIENT.Ginger, quantity: 6 }, { name: "Fancy Egg", type: INGREDIENT.Egg, quantity: 5 }, { name: "Soft Potato", type: INGREDIENT.Potato, quantity: 3 }] },
    { name: "Water Veil Tofu Salad", type: RECIPE_TYPE.Salad, base_power: 1843, ingredients: [{ name: "Greengrass Soybeans", type: INGREDIENT.Soybeans, quantity: 10 }, { name: "Snoozy Tomato", type: INGREDIENT.Tomato, quantity: 6 }] },

]

const allRecipes = [...dessertRecipes, ...curryRecipes, ...saladRecipes]

export default allRecipes;