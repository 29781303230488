import { FC } from "react"
import { styled } from "styled-components"

import moomooMilk from '../images/dishes/warm-moomoo-milk.png'
import chocolateCake from '../images/dishes/sweet-scent-chocolate-cake.png'
import gingerCookies from '../images/dishes/stead-fast-ginger-cookies.png'
import kissSmoothie from '../images/dishes/lovely-kiss-smoothie.png'
import applePie from '../images/dishes/lucky-chant-apple-pie.png'
import mixedJuice from '../images/dishes/mixed-juice.png'
import fruityFlan from '../images/dishes/jigglypuff-fruity-flan.png'
import proteinSmoothie from '../images/dishes/hustle-protein-smoothie.png'
import soyDonuts from '../images/dishes/huge-power-soy-donuts.png'
import appleJuice from '../images/dishes/fancy-apple-juice.png'
import gingerTea from '../images/dishes/ember-ginger-tea.png'
import sodaPop from '../images/dishes/craft-soda-pop.png'
import soyCake from '../images/dishes/cloud-nine-soy-cake.png'
import bigMalasada from '../images/dishes/big-malasada.png'
import sweetPotatoes from '../images/dishes/fluffy-sweet-potatoes.png'
import neroliTea from '../images/dishes/neroli-restorative-tea.png'
import vegetableJuice from '../images/dishes/stalwart-vegetable-juice.png'

import mixedCurry from '../images/dishes/mixed-curry.png'
import beanburgerCurry from '../images/dishes/beanburger-curry.png'
import bulkupBeanCurry from '../images/dishes/bulkup-bean-curry.png'
import dreamEaterButterCurry from '../images/dishes/dream-eater-butter-curry.png'
import droughtKatsuCurry from '../images/dishes/drought-katsu-curry.png'
import eggBombCurry from '../images/dishes/egg-bomb-curry.png'
import fancyAppleCurry from '../images/dishes/fancy-apple-curry.png'
import grilledTailCurry from '../images/dishes/grilled-tail-curry.png'
import heartyCheeseBurgerCurry from '../images/dishes/hearty-cheese-burger-curry.png'
import meltyOmeletteCurry from '../images/dishes/melty-omelette-curry.png'
import mildHoneyCurry from '../images/dishes/mild-honey-curry.png'
import ninjaCurry from '../images/dishes/ninja-curry.png'
import simpleChowder from '../images/dishes/simple-chowder.png'
import softPotatoChowder from '../images/dishes/soft-potato-chowder.png'
import solarPowerTomatoCurry from '../images/dishes/solar-power-tomato-curry.png'
import spicyLeekCurry from '../images/dishes/spicy-leek-curry.png'
import sporeMushroomCurry from '../images/dishes/spore-mushroom-curry.png'


import beanHamSalad from '../images/dishes/bean-ham-salad.png'
import contraryChocolateMeatSalad from '../images/dishes/contrary-chocolate-meat-salad.png'
import dazzlingAppleCheeseSalad from '../images/dishes/dazzling-apple-cheese-salad.png'
import fancyAppleSalad from '../images/dishes/fancy-apple-salad.png'
import gluttonyPotatoSalad from '../images/dishes/gluttony-potato-salad.png'
import heatWaveTofuSalad from '../images/dishes/heat-wave-tofu-salad.png'
import immunityLeekSalad from '../images/dishes/immunity-leek-salad.png'
import mixedSalad from '../images/dishes/mixed-salad.png'
import moomooCapreseSalad from '../images/dishes/moomoo-caprese-salad.png'
import ninjaSalad from '../images/dishes/ninja-salad.png'
import overheatGingerSalad from '../images/dishes/overheat-ginger-salad.png'
import slowpokeTailPepperSalad from '../images/dishes/slowpoke-tail-pepper-salad.png'
import snoozyTomatoSalad from '../images/dishes/snoozy-tomato-salad.png'
import snowCloakCaesarSalad from '../images/dishes/snow-cloak-caesar-salad.png'
import sporeMushroomSalad from '../images/dishes/spore-mushroom-salad.png'
import superpowerExtremeSalad from '../images/dishes/superpower-extreme-salad.png'
import waterVeilTofuSalad from '../images/dishes/water-veil-tofu-salad.png'


interface Props {
    name: string
    size?: number
}

export const MenuImg = styled.img<{ maxSize?: number }>`
    position: relative;
    max-width: ${props => props.maxSize ?? 30}px;
    display: block;
`

const RecipeIcon: FC<Props> = ({ name, size }) => {
    let icon;
    switch (name) {
        // Desserts
        case "Big Malasada":
            icon = bigMalasada;
            break;
        case "Cloud Nine Soy Cake":
            icon = soyCake;
            break;
        case "Craft Soda Pop":
            icon = sodaPop;
            break;
        case "Ember Ginger Tea":
            icon = gingerTea;
            break;
        case "Fancy Apple Juice":
            icon = appleJuice;
            break;
        case "Fluffy Sweet Potatoes":
            icon = sweetPotatoes;
            break;
        case "Huge Power Soy Donuts":
            icon = soyDonuts;
            break;
        case "Hustle Protein Smoothie":
            icon = proteinSmoothie;
            break;
        case "Jigglypuff's Fruity Flan":
            icon = fruityFlan;
            break;
        case "Lovely Kiss Smoothie":
            icon = kissSmoothie;
            break;
        case "Lucky Chant Apple Pie":
            icon = applePie;
            break;
        case "Mixed Juice":
            icon = mixedJuice;
            break;
        case "Neroli's Restorative Tea":
            icon = neroliTea;
            break;
        case "Stalwart Vegetable Juice":
            icon = vegetableJuice;
            break;
        case "Steadfast Ginger Cookies":
            icon = gingerCookies;
            break;
        case "Sweet Scent Chocolate Cake":
            icon = chocolateCake;
            break;
        case "Warm Moomoo Milk":
            icon = moomooMilk;
            break;
        // Curries
        case "Mixed Curry":
            icon = mixedCurry;
            break;
        case "Beanburger Curry":
            icon = beanburgerCurry;
            break;
        case "Bulk Up Bean Curry":
            icon = bulkupBeanCurry;
            break;
        case "Dream Eater Butter Curry":
            icon = dreamEaterButterCurry;
            break;
        case "Drought Katsu Curry":
            icon = droughtKatsuCurry;
            break;
        case "Egg Bomb Curry":
            icon = eggBombCurry;
            break;
        case "Fancy Apple Curry":
            icon = fancyAppleCurry;
            break;
        case "Grilled Tail Curry":
            icon = grilledTailCurry;
            break;
        case "Hearty Cheeseburger Curry":
            icon = heartyCheeseBurgerCurry;
            break;
        case "Melty Omelette Curry":
            icon = meltyOmeletteCurry;
            break;
        case "Mild Honey Curry":
            icon = mildHoneyCurry;
            break;
        case "Ninja Curry":
            icon = ninjaCurry;
            break;
        case "Simple Chowder":
            icon = simpleChowder;
            break;
        case "Soft Potato Chowder":
            icon = softPotatoChowder;
            break;
        case "Solar Power Tomato Curry":
            icon = solarPowerTomatoCurry;
            break;
        case "Spicy Leek Curry":
            icon = spicyLeekCurry;
            break;
        case "Spore Mushroom Curry":
            icon = sporeMushroomCurry;
            break;
            // Salads
        case "Bean Ham Salad":
            icon = beanHamSalad;
            break;
        case "Contrary Chocolate Meat Salad":
            icon = contraryChocolateMeatSalad;
            break;
        case "Dazzling Apple Cheese Salad":
            icon = dazzlingAppleCheeseSalad;
            break;
        case "Fancy Apple Salad":
            icon = fancyAppleSalad;
            break;
        case "Gluttony Potato Salad":
            icon = gluttonyPotatoSalad;
            break;
        case "Heat Wave Tofu Salad":
            icon = heatWaveTofuSalad;
            break;
        case "Immunity Leek Salad":
            icon = immunityLeekSalad;
            break;
        case "Mixed Salad":
            icon = mixedSalad;
            break;
        case "Moomoo Caprese Salad":
            icon = moomooCapreseSalad;
            break;
        case "Ninja Salad":
            icon = ninjaSalad;
            break;
        case "Overheat Ginger Salad":
            icon = overheatGingerSalad;
            break;
        case "Slowpoke Tail Pepper Salad":
            icon = slowpokeTailPepperSalad;
            break;
        case "Snoozy Tomato Salad":
            icon = snoozyTomatoSalad;
            break;
        case "Snow Cloak Caesar Salad":
            icon = snowCloakCaesarSalad;
            break;
        case "Spore Mushroom Salad":
            icon = sporeMushroomSalad;
            break;
        case "Superpower Extreme Salad":
            icon = superpowerExtremeSalad;
            break;
        case "Water Veil Tofu Salad":
            icon = waterVeilTofuSalad;
            break;
        default:
            icon = mixedJuice;
    }

    return (
        <MenuImg src={icon} maxSize={size} alt="" />
    )
}

export default RecipeIcon