import { FC, useEffect, useState } from "react"
import { logEvent } from "firebase/analytics"
import { useNavigate, useParams } from "react-router-dom"
import { analytics } from "../App"

import allRecipes, { INGREDIENT, RECIPE_SORT, RECIPE_SORT_TYPE, RECIPE_TYPE, SORT_DIRECTION } from "../data/recipes"
import Button from "../components/button"
import Box from "../components/box"
import Heading from "../components/heading"
import Select from "../components/select"
import Text from "../components/text"
import SEO from "../components/seo"

import { convertURLToIslandType, convertURLToRecipeType, groupBy } from "../helpers/helpers"
import { useQuery } from "../hooks/useQueryParams"
import Filter from "../images/icons/filter"
import Modal from "../components/modal"
import Overlay from "../components/overlay"
import { ISLAND, SLEEP_TYPE, SNORLAX_RATING, allIslands, getSnorlaxLevel } from "../data/islands"
import pokemon, { Pokemon } from "../data/pokemon"
import SleepRankIcon from "../components/sleep-rank-icon"
import PokemonCard from "../components/pokemon-card"

interface Props {
    sortBy?: SLEEP_TYPE | 'all'
    sortDirection?: SORT_DIRECTION
}

const Islands: FC<Props> = ({ sortBy = 'all', sortDirection = SORT_DIRECTION.ASC }) => {
    const [filteredIslands, setFilteredIslands] = useState<[string, Array<Pokemon>][]>([])
    const [pokemonAccess, setPokemonAccess] = useState<Array<Pokemon>>([])
    const [snorlaxRating, setSnorlaxRating] = useState(SNORLAX_RATING.Basic1)
    const [sortType, setSortType] = useState<SLEEP_TYPE | string>(sortBy)
    // Get from URL
    let { islandSlug: island } = useParams()
    let query = useQuery()
    const lastSeenIsland = localStorage.getItem('lastSeenIsland')
    const lastSeenIslandSort = localStorage.getItem('lastSeenIslandSort')
    const [islandFilterType, setIslandFilterType] = useState(convertURLToIslandType(island) || lastSeenIsland || ISLAND.Greengrass)
    const [direction, setDirection] = useState(query.get('sort') || lastSeenIslandSort || sortDirection)
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false)

    // @ts-ignore
    // const temp = Object.keys(INGREDIENT).filter(key => !isNaN(key)).map(item => ({ selected: true, type: Number(item) as INGREDIENT }))
    // const [ingredients, setIngredients] = useState(temp)

    useEffect(() => {
        let temp: Array<Pokemon> = pokemon.filter(pokemon => pokemon.locations.some(pok => pok.island === islandFilterType)).map(pok => ({ ...pok, snorlaxRating: pok.locations.reduce((acc, currentValue) => currentValue.snorlaxRating, 0) }))
        // let temp2 = ingredients.filter(ingredient => ingredient.selected).map(i => i.type)
        // temp = temp.filter(recipe => recipe.ingredients.map(r => r.type).every(val => temp2.includes(val)))
        if (sortType !== 'all') {
            temp = temp.filter(currentValue => currentValue.sleepType === sortType)
        }

        let temp2: [string, Array<Pokemon>][] = Object.entries(groupBy(temp, 'snorlaxRating'))
        let haveAccess = temp2.filter(([rating, _]) => Number(rating) < snorlaxRating)
        temp2 = temp2.filter(([rating, _]) => Number(rating) >= snorlaxRating)
        const clean = haveAccess.flat().filter(x => Array.isArray(x)).flat().map(i => i as Pokemon)
        setPokemonAccess([...clean])
        if (direction === SORT_DIRECTION.DESC) {
            temp2 = temp2.reverse()
        }
        setFilteredIslands([...temp2])
    }, [islandFilterType, direction, sortType, snorlaxRating])


    useEffect(() => {
        // On load
        logEvent(analytics, 'viewed_page_islands')
    }, [])

    useEffect(() => {
        localStorage.setItem('lastSeenIsland', islandFilterType)
        localStorage.setItem('lastSeenIslandSort', direction)
    }, [islandFilterType, direction])

    useEffect(() => {
        // When route changes update recipe type
        if (island === "greengrass") {
            setIslandFilterType(ISLAND.Greengrass)
        } else if (island === "cyan") {
            setIslandFilterType(ISLAND.Cyan)
        } else if (island === "taupe") {
            setIslandFilterType(ISLAND.Taupe)
        }
    }, [island])

    useEffect(() => {
        if (islandFilterType === ISLAND.Greengrass) {
            navigate(`/islands/greengrass?sort=${direction}`, { preventScrollReset: true })
        } else if (islandFilterType === ISLAND.Cyan) {
            navigate(`/islands/cyan?sort=${direction}`, { preventScrollReset: true })
        } else if (islandFilterType === ISLAND.Taupe) {
            navigate(`/islands/taupe?sort=${direction}`, { preventScrollReset: true })
        }
    }, [islandFilterType, direction, navigate])

    const toggleDirection = () => {
        if (direction === SORT_DIRECTION.DESC) {
            setDirection(SORT_DIRECTION.ASC)
            logEvent(analytics, 'clicked_sort_order', {
                order: 'asc',
                type: 'snorlax-rating'
            })
        } else {
            setDirection(SORT_DIRECTION.DESC)
            logEvent(analytics, 'clicked_sort_order', {
                order: 'desc',
                type: 'snorlax-rating'
            })
        }
    }

    const setIslandType = (type: ISLAND) => {
        setIslandFilterType(type);
        logEvent(analytics, 'clicked_view_island', {
            type
        })
        // update local storage
    }

    const isGreengrass = () => islandFilterType === ISLAND.Greengrass
    const isCyan = () => islandFilterType === ISLAND.Cyan
    const isTaupe = () => islandFilterType === ISLAND.Taupe

    const updateSortingType = (value: string) => {
        const type = value as SLEEP_TYPE
        // logEvent(analytics, 'updated_recipe_sorting_type', {
        //     type: type === RECIPE_SORT_TYPE.Ingredients ? 'ingredients' : 'power'
        // })
        setSortType(type)
    }

    // const selectedOpenModal = () => {
    //     setOpenModal(true)
    //     logEvent(analytics, 'opened_recipe_filter')
    // }

    // const ingredientSelected = (index: number) => {
    //     const item = ingredients[index]
    //     item.selected = !item.selected
    //     setIngredients([...ingredients])
    //     logEvent(analytics, 'recipe_ingredient_filter_toggled', {
    //         state: item.selected,
    //         type: item.type
    //     })
    // }

    // const toggleIngredientsSelection = (isSelected: boolean) => {
    //     ingredients.forEach(item => item.selected = isSelected)
    //     setIngredients([...ingredients])
    // }

    return (
        <>
            <SEO title={`Islands`} />
            <Box>
                <Heading as="h1" fontSize={7} mb={5}>Islands</Heading>
            </Box>
            {/* <Modal title="Filter Recipes" isOpen={openModal} close={() => setOpenModal(false)}>
                <Heading as="h3" my={3} fontSize={3}>Select ingredients:</Heading>

                <Box display="flex" flexFlow="row wrap" alignItems="flex-start" justifyContent="flex-start">
                    {
                        ingredients.map((item, index) => (
                            <Button display="inline" p={1} m={1} bg="transparent" border="0" flexBasis="15%" onClick={() => ingredientSelected(index)}>
                                <IngredientIcon type={item.type} bg={item.selected ? 'primary' : 'grey.2'} />
                            </Button>
                        ))
                    }
                </Box>
                <Button onClick={() => {
                    toggleIngredientsSelection(false)
                }} my={3}>Clear</Button>
                <Button onClick={() => {
                    toggleIngredientsSelection(true)
                }}>Select All</Button>

            </Modal> */}
            <Box display="flex">
                <Button variant="none" mb={4} mr="-1px" borderRadius="30px 0 0 30px" onClick={() => setIslandType(ISLAND.Greengrass)}
                    color={isGreengrass() ? "red" : "text"}
                    borderColor={isGreengrass() ? "primary" : "grey.3"}
                    bg={isGreengrass() ? "blue.0" : "white"}
                >
                    Greengrass
                </Button>
                <Button mb={4} mr="-1px" borderRadius="0" onClick={() => setIslandType(ISLAND.Cyan)}
                    color={isCyan() ? "primary" : "text"}
                    borderColor={isCyan() ? "primary" : "grey.3"}
                    bg={isCyan() ? "blue.0" : "white"}
                >Cyan Beach</Button>
                <Button mb={4} mr={0} borderRadius="0 30px 30px 0" onClick={() => setIslandType(ISLAND.Taupe)}
                    color={isTaupe() ? "primary" : "text"}
                    borderColor={isTaupe() ? "primary" : "grey.3"}
                    bg={isTaupe() ? "blue.0" : "white"}
                >Taupe Hollow</Button>
            </Box>
            <Box display="flex" alignItems="center" mb={4}>
                <Select px={6} pr={10} m={0} mr={3} onChange={(evt) => updateSortingType(evt.currentTarget.value)}>
                    {/* <SortIcon width="16px" fill="white" /> &nbsp;Ingredients */}
                    <option value="all">All Sleep Types</option>
                    <option value={SLEEP_TYPE.Dozing}>Dozing</option>
                    <option value={SLEEP_TYPE.Snoozing}>Snoozing</option>
                    <option value={SLEEP_TYPE.Slumbering}>Slumbering</option>
                </Select>
                <Button m={0} mr={3} p={1} fontSize={3} width="38px" height="38px" flex="0 0 38px" borderRadius="40px" onClick={() => toggleDirection()}>{direction === SORT_DIRECTION.ASC ? String.fromCharCode(0x2191) : String.fromCharCode(0x2193)}</Button>
                {/* <Button m={0} mr={3} p={1} fontSize={3} width="38px" height="38px" flex="0 0 38px" borderRadius="40px" onClick={() => selectedOpenModal()} borderColor={ingredients.some(i => !i.selected) ? 'primary' : 'grey.3'}><Filter width="13px" /></Button> */}
                <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1 1 100%">
                    <Text fontSize={2} as="label" m={0}><strong>Snorlax Level:</strong> {getSnorlaxLevel(snorlaxRating)}</Text>
                    <input type="range" step="1" min="0" max={SNORLAX_RATING.Master20} value={snorlaxRating} style={{ width: "100%", maxWidth: "250px" }} onChange={(evt) => setSnorlaxRating(Number(evt.currentTarget.value))} />
                </Box>
            </Box>
            {pokemonAccess.length > 0 && <Heading as="h2" fontSize={6}>Pokemon you have access to:</Heading>}
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
                {
                    pokemonAccess.length > 0 && pokemonAccess.map((item) => (
                        <PokemonCard pokemon={item} />
                    ))
                }
            </Box >
            <Box display="flex" flexDirection={["column", "column", "column", "column"]} flexWrap={["nowrap", "nowrap", "nowrap", "wrap"]} justifyContent={["space-between", "space-between", "space-between", "space-between"]}>
                {
                    /* @ts-ignore */
                    filteredIslands.length > 0 && filteredIslands.map(([snorlaxRating, items]) => (
                        <>
                            <Box position="sticky" display="flex" alignItems="center" mt={2} mb={2} bg="grey.1" borderRadius="8px" top="68px" zIndex="10">
                                <SleepRankIcon type={Number(snorlaxRating)} size="45px" />
                                <Heading as="h2" fontSize={7} m={0} ml={2}>{getSnorlaxLevel(Number(snorlaxRating))}</Heading>
                            </Box>
                            <Box display="flex" flexFlow="row wrap" mb={5}>
                                {items.map(i => (
                                    <PokemonCard pokemon={i} />
                                ))}
                            </Box>
                        </>
                    ))
                }
            </Box>
            {/* <Overlay isVisible={openModal} /> */}
        </>
    )
}

export default Islands