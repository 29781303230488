import styled from 'styled-components'
import { space, layout, color, typography, SpaceProps, LayoutProps, flexbox, FlexboxProps, TypographyProps } from '@techstack/styled-system'

interface Props extends SpaceProps, LayoutProps, FlexboxProps, TypographyProps {}

const Text = styled.p<Props>`
  color: inherit;
  transition: color .15s ease-out;
  line-height: 1.45;
  margin-bottom: ${({theme}) => theme.space[5]}px;
  
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${typography}
`

export default Text;