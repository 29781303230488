import { FC, useEffect, useState } from "react"
import { logEvent } from "firebase/analytics"
import { useNavigate, useParams } from "react-router-dom"
import { analytics } from "../App"

import allRecipes, { INGREDIENT, RECIPE_SORT, RECIPE_SORT_TYPE, RECIPE_TYPE, SORT_DIRECTION } from "../data/recipes"
import RecipeCard from "../components/recipe-card"
import Button from "../components/button"
import Box from "../components/box"
import Heading from "../components/heading"
import Select from "../components/select"
import Text from "../components/text"
import SEO from "../components/seo"

import { convertURLToRecipeType } from "../helpers/helpers"
import { useQuery } from "../hooks/useQueryParams"
import Filter from "../images/icons/filter"
import Modal from "../components/modal"
import IngredientIcon from "../components/ingredient-icon"
import Overlay from "../components/overlay"

interface Props {
    sortBy?: RECIPE_SORT
    sortDirection?: SORT_DIRECTION
}

const Recipes: FC<Props> = ({ sortBy = RECIPE_SORT.NumberOfIngredients, sortDirection = SORT_DIRECTION.ASC }) => {
    const [filteredRecipes, setFilteredRecipes] = useState<typeof allRecipes>([])
    const [potSize, setPotSize] = useState(99)
    const [sortType, setSortType] = useState(RECIPE_SORT_TYPE.Ingredients)
    // Get from URL
    let { recipeType: recipeTypeURL } = useParams()
    let query = useQuery()
    const lastSeenRecipeType = localStorage.getItem('lastSeenRecipeType')
    const lastSeenRecipeSort = localStorage.getItem('lastSeenRecipeSort')
    const [recipeFilterType, setRecipeFilterType] = useState(convertURLToRecipeType(recipeTypeURL) || lastSeenRecipeType || RECIPE_TYPE.Dessert)
    const [direction, setDirection] = useState(query.get('sort') || lastSeenRecipeSort || sortDirection)
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false)

    // @ts-ignore
    const temp = Object.keys(INGREDIENT).filter(key => !isNaN(key)).map(item => ({ selected: true, type: Number(item) as INGREDIENT }))
    const [ingredients, setIngredients] = useState(temp)

    useEffect(() => {
        let temp = allRecipes.filter(recipe => recipe.type === recipeFilterType)
        let temp2 = ingredients.filter(ingredient => ingredient.selected).map(i => i.type)
        temp = temp.filter(recipe => recipe.ingredients.map(r => r.type).every(val => temp2.includes(val)))
        if (sortType === RECIPE_SORT_TYPE.Ingredients) {
            temp = temp.sort((a, b) => a.ingredients.reduce((accumulator, currentValue) => currentValue.quantity + accumulator, 0) - b.ingredients.reduce((accumulator, currentValue) => currentValue.quantity + accumulator, 0))
        } else {
            temp = temp.sort((a, b) => a.base_power - b.base_power)
        }
        if (direction === SORT_DIRECTION.DESC) {
            temp = temp.reverse()
        }
        setFilteredRecipes([...temp])
    }, [recipeFilterType, direction, sortType, ingredients])


    useEffect(() => {
        // On load
        logEvent(analytics, 'viewed_page_recipes')
    }, [])

    useEffect(() => {
        localStorage.setItem('lastSeenRecipeType', recipeFilterType)
        localStorage.setItem('lastSeenRecipeSort', direction)
    }, [recipeFilterType, direction])

    useEffect(() => {
        // When route changes update recipe type
        if (recipeTypeURL === "desserts") {
            setRecipeFilterType(RECIPE_TYPE.Dessert)
        } else if (recipeTypeURL === "curries") {
            setRecipeFilterType(RECIPE_TYPE.Curry)
        } else if (recipeTypeURL === "salads") {
            setRecipeFilterType(RECIPE_TYPE.Salad)
        }
    }, [recipeTypeURL])

    useEffect(() => {
        if (recipeFilterType === RECIPE_TYPE.Dessert) {
            navigate(`/recipes/desserts?sort=${direction}`, { preventScrollReset: true })
        } else if (recipeFilterType === RECIPE_TYPE.Curry) {
            navigate(`/recipes/curries?sort=${direction}`, { preventScrollReset: true })
        } else if (recipeFilterType === RECIPE_TYPE.Salad) {
            navigate(`/recipes/salads?sort=${direction}`, { preventScrollReset: true })
        }
    }, [recipeFilterType, direction, navigate])

    const toggleDirection = () => {
        if (direction === SORT_DIRECTION.DESC) {
            setDirection(SORT_DIRECTION.ASC)
            logEvent(analytics, 'clicked_sort_order', {
                order: 'asc',
                type: 'ingredients'
            })
        } else {
            setDirection(SORT_DIRECTION.DESC)
            logEvent(analytics, 'clicked_sort_order', {
                order: 'desc',
                type: 'ingredients'
            })
        }
    }

    const setRecipeType = (type: RECIPE_TYPE) => {
        setRecipeFilterType(type);
        logEvent(analytics, 'clicked_view_recipes', {
            type
        })
        // update local storage
    }

    const isDessert = () => recipeFilterType === RECIPE_TYPE.Dessert
    const isCurries = () => recipeFilterType === RECIPE_TYPE.Curry
    const isSalads = () => recipeFilterType === RECIPE_TYPE.Salad

    const updateSortingType = (value: string) => {
        const type = Number(value) as RECIPE_SORT_TYPE
        logEvent(analytics, 'updated_recipe_sorting_type', {
            type: type === RECIPE_SORT_TYPE.Ingredients ? 'ingredients' : 'power'
        })
        setSortType(type)
    }

    const selectedOpenModal = () => {
        setOpenModal(true)
        logEvent(analytics, 'opened_recipe_filter')
    }

    const ingredientSelected = (index: number) => {
        const item = ingredients[index]
        item.selected = !item.selected
        setIngredients([...ingredients])
        logEvent(analytics, 'recipe_ingredient_filter_toggled', {
            state: item.selected,
            type: item.type
        })
    }

    const toggleIngredientsSelection = (isSelected: boolean) => {
        ingredients.forEach(item => item.selected = isSelected)
        setIngredients([...ingredients])
    }

    return (
        <>
            <SEO title={`Recipes`} />
            <Box>
                <Heading as="h1" fontSize={7} mb={5}>Recipes</Heading>
            </Box>
            <Modal title="Filter Recipes" isOpen={openModal} close={() => setOpenModal(false)}>
                <Heading as="h3" my={3} fontSize={3}>Select ingredients:</Heading>

                <Box display="flex" flexFlow="row wrap" alignItems="flex-start" justifyContent="flex-start">
                    {
                        ingredients.map((item, index) => (
                            <Button display="inline" p={1} m={1} bg="transparent" border="0" flexBasis="15%" onClick={() => ingredientSelected(index)}>
                                <IngredientIcon type={item.type} bg={item.selected ? 'primary' : 'grey.2'} />
                            </Button>
                        ))
                    }
                </Box>
                <Button onClick={() => {
                    toggleIngredientsSelection(false)
                }} my={3}>Clear</Button>
                <Button onClick={() => {
                    toggleIngredientsSelection(true)
                }}>Select All</Button>

            </Modal>
            <Box display="flex">
                <Button variant="none" mb={4} mr="-1px" borderRadius="30px 0 0 30px" onClick={() => setRecipeType(RECIPE_TYPE.Dessert)} color={isDessert() ? "red" : "text"} borderColor={isDessert() ? "primary" : "grey.3"} bg={isDessert() ? "blue.0" : "white"}>Drinks/Desserts</Button>
                <Button mb={4} mr="-1px" borderRadius="0" onClick={() => setRecipeType(RECIPE_TYPE.Curry)} color={isCurries() ? "primary" : "text"} borderColor={isCurries() ? "primary" : "grey.3"} bg={isCurries() ? "blue.0" : "white"}>Curries</Button>
                <Button mb={4} mr={0} borderRadius="0 30px 30px 0" onClick={() => setRecipeType(RECIPE_TYPE.Salad)} color={isSalads() ? "primary" : "text"} borderColor={isSalads() ? "primary" : "grey.3"} bg={isSalads() ? "blue.0" : "white"}>Salads</Button>
            </Box>
            <Box display="flex" alignItems="center" mb={4}>
                <Select px={6} pr={10} m={0} mr={3} onChange={(evt) => updateSortingType(evt.currentTarget.value)}>
                    {/* <SortIcon width="16px" fill="white" /> &nbsp;Ingredients */}
                    <option value={RECIPE_SORT_TYPE.Ingredients}>Ingredients</option>
                    <option value={RECIPE_SORT_TYPE.Power}>Power</option>
                </Select>
                <Button m={0} mr={3} p={1} fontSize={3} width="38px" height="38px" flex="0 0 38px" borderRadius="40px" onClick={() => toggleDirection()}>{direction === SORT_DIRECTION.ASC ? String.fromCharCode(0x2191) : String.fromCharCode(0x2193)}</Button>
                <Button m={0} mr={3} p={1} fontSize={3} width="38px" height="38px" flex="0 0 38px" borderRadius="40px" onClick={() => selectedOpenModal()} borderColor={ingredients.some(i => !i.selected) ? 'primary' : 'grey.3'}><Filter width="13px" /></Button>
                <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1 1 100%">
                    <Text fontSize={2} as="label" m={0}><strong>Pot Size:</strong> {potSize}</Text>
                    <input type="range" step="3" min="15" max="99" value={potSize} style={{ width: "100%", maxWidth: "250px" }} onChange={(evt) => setPotSize(Number(evt.currentTarget.value))} />
                </Box>
            </Box>
            <Box display="flex" flexDirection={["column", "column", "column", "row"]} flexWrap={["nowrap", "nowrap", "nowrap", "wrap"]} justifyContent={["space-between", "space-between", "space-between", "space-between"]}>
                {
                    filteredRecipes.length > 0 && filteredRecipes.map(rec => (
                        <RecipeCard key={rec.name} flexBasis={["100%", "100%", "100%", "49%"]} recipe={rec} isCookable={potSize} showStat={sortType} />
                    ))
                }
            </Box>
            <Overlay isVisible={openModal} />
        </>
    )
}

export default Recipes