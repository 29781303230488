import { FC } from "react"
import Box from "./box";
import Heading from "./heading";
import Text from "./text";
import { INGREDIENT, RECIPE_SORT_TYPE } from "../data/recipes";
import IngredientIcon from "./ingredient-icon";
import RecipeIcon from "./recipe-icon";
import { FlexboxProps } from "styled-system";

interface Recipe {
    name: string;
    base_power: number;
    ingredients: Array<{ name: string, quantity: number, type: INGREDIENT }>
}

interface Props extends FlexboxProps {
    recipe: Recipe
    isCookable: number
    showStat: RECIPE_SORT_TYPE
}

const RecipeCard: FC<Props> = ({ recipe, isCookable, showStat, ...props }) => {
    const totalIngredients = (asString: boolean = false) => {
        let temp = recipe.ingredients.reduce((accumulator, currentValue) => currentValue.quantity + accumulator, 0)
        return temp === 0 && asString ? "1+" : temp
    }

    return (
        <Box display="flex" border="1px solid" borderColor="grey.2" borderRadius="medium" mb="4" bg="white" overflow="hidden" position="relative" boxShadow="card" {...props}>
            <Box display="flex" justifyContent="center" alignItems="center" py={2} pl={2} bg="grey.0" flexBasis="22%" maxWidth="100px">
                <RecipeIcon name={recipe.name} size={80} />
            </Box>
            <Box p={5} pl={4} flex="1">
                <Heading as="h3" fontSize={4} mt={0} mb={4} lineHeight="1.2" maxWidth="calc(100% - 70px)">{recipe.name}</Heading>
                <Box display="flex" flexDirection="column">
                    <Heading as="h4" fontSize={2} m={0} mb={3} lineHeight="1">Ingredients: </Heading>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                        {recipe.ingredients.length > 0 ? recipe.ingredients.map(ingredient => (
                            <Box key={ingredient.name} pr={3} display="flex" alignItems="center">
                                <IngredientIcon type={ingredient.type} size={22} />
                                <Text fontSize={3} lineHeight="1" m={0} pl={2}>{ingredient.quantity}</Text>
                            </Box>
                        )) : <Text fontSize={2} my={0}>Any number of Ingredients that do not match a recipe.</Text>}
                    </Box>
                </Box>
                {/* <Text fontSize={1} lineHeight="1" m={0} mt={5} fontWeight="normal">Total Ingredients: {totalIngredients(true)}</Text> */}
            </Box>
            {showStat === RECIPE_SORT_TYPE.Ingredients ?
                <Box position="absolute" right="0" top="0" bg={isCookable >= Number(totalIngredients()) ? "blue.6" : "grey.2"} py={2} px={4} borderBottomLeftRadius="medium">
                    <Text fontSize={2} color="whiteAlways" m={0} fontWeight="bold">{totalIngredients(true)} Ingr</Text>
                </Box>
                : <Box position="absolute" right="0" top="0" bg={"blue.6"} py={2} px={4} borderBottomLeftRadius="medium">
                    <Text fontSize={2} color="whiteAlways" m={0} fontWeight="bold">{recipe.base_power} Power</Text>
                </Box>}
        </Box>
    )
}

export default RecipeCard