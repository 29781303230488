import { FC } from "react"
import SEO from "../components/seo"
import Box from "../components/box"
import Text from "../components/text"
import { Link } from "react-router-dom"
import cookingIcon from "../images/cooking.png"
import eventIcon from "../images/events.png"
import islandsIcon from "../images/islands.png"

const Home: FC = () => {
    return (
        <>
            <SEO title={`Home`} />
            <Box>
                <Link to={`/events/`} style={{ textDecoration: 'none' }}>
                    <Box display="flex" border="1px solid" borderColor="grey.2" borderRadius="xxlarge" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                        <Box display="flex" justifyContent="center" alignItems="center" py={2} pl={2} bg="grey.0" flexBasis="22%" maxWidth="100px">
                            <img style={{maxWidth: "90px"}} src={eventIcon} alt="" />
                        </Box>
                        <Box display="flex" alignItems="center" p={3} pl={4} flex="1">
                            <Text m={0} fontWeight="bold">Upcoming Events</Text>
                        </Box>
                    </Box>
                </Link>
                <Link to={`/recipes/`} style={{ textDecoration: 'none' }}>
                    <Box display="flex" border="1px solid" borderColor="grey.2" borderRadius="xxlarge" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                        <Box display="flex" justifyContent="center" alignItems="center" py={2} pl={2} bg="grey.0" flexBasis="22%" maxWidth="100px">
                            <img style={{maxWidth: "90px"}} src={cookingIcon} alt="" />
                        </Box>
                        <Box display="flex" alignItems="center" p={3} pl={4} flex="1">
                            <Text m={0} fontWeight="bold">Recipes</Text>
                        </Box>
                    </Box>
                </Link>
                <Link to={`/islands/`} style={{ textDecoration: 'none' }}>
                    <Box display="flex" border="1px solid" borderColor="grey.2" borderRadius="xxlarge" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                        <Box display="flex" justifyContent="center" alignItems="center" py={2} pl={2} bg="grey.0" flexBasis="22%" maxWidth="100px">
                            <img style={{maxWidth: "90px"}} src={islandsIcon} alt="" />
                        </Box>
                        <Box display="flex" alignItems="center" p={3} pl={4} flex="1">
                            <Text m={0} fontWeight="bold">Islands</Text>
                        </Box>
                    </Box>
                </Link>
                {/* <Link to={`/about/`} style={{ textDecoration: 'none' }}>
                    <Box display="flex" flexDirection="column" border="1px solid" borderColor="grey.2" borderRadius="xxlarge" mb={3} bg="white" overflow="hidden" position="relative" boxShadow="card">
                        <Box p={3} pl={4} flex="1">
                            <Text fontWeight="bold">About</Text>
                        </Box>
                    </Box>
                </Link> */}
            </Box>
        </>
    )
}

export default Home